import React, { useEffect, useCallback } from 'react';
import Alert from 'react-bootstrap/Alert';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { wh, fontSize } from '../mixins';
import { setError, selectError } from '../features/error/errorSlice';

const StyledAlert = styled(Alert)`
  position: absolute;
  ${wh('90%', 'auto')};
  max-width: 327px;
  left: 50%;
  top: 6%;
  transform: translate(-50%, -20%);
  min-height: 50px;
  background-color: #ff5b5b;
  border-radius: 8px;
  padding: 17px 50px 17px 17px;
  border: none;
  z-index: 100;

  p {
    margin: 0;
    ${fontSize(14)};
    line-height: 17px;
    color: #fff;
    font-weight: 300;
  }

  button {
    opacity: 1;

    &:focus {
      outline: none;
    }
  }

  span {
    color: #fff;
    box-shadow: none;
    font-weight: 400;
    text-shadow: none;
  }
`;

const UIAlert = () => {
  const error = useSelector(selectError);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const clearError = useCallback(
    () => dispatch(setError({ show: false, text: '', type: '' })),
    [dispatch]
  );

  let doesEmailExist =
    error &&
    error.text &&
    typeof error.text === 'string' &&
    error.text.replace('.', '').toLowerCase() ===
      'the email has already been taken';

  useEffect(() => {
    if (error.show) {
      setTimeout(clearError, 3000);
    }
  }, [error, clearError]);

  return (
    <StyledAlert
      show={error.show}
      onClose={clearError}
      variant="danger"
      dismissible
    >
      <p>{doesEmailExist ? t('email_exist') : error.text}</p>
    </StyledAlert>
  );
};

export default UIAlert;
