import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { centeredBG, firstScreensContainer } from '../../mixins';
import ScreenContent from '../../UI/ScreenContent';
import ProgrBar from '../../UI/ProgrBar';
import { sendAnalyticsEvents } from '../../analytics/common';
import { EVENTS } from '../../analytics/events';
import { selectWebp } from '../ui/uiSlice';
import { selectEventsData } from '../events/eventsSlice';

const ScreenContainer = styled.div`
  background-image: ${(props) =>
    props.supportsWebP
      ? `url('../assets/desktop/screen-four-desk.webp')`
      : `url('../assets/desktop/screen-four-desk.png')`};
  ${firstScreensContainer};
  ${centeredBG};
  background-position: top;
  @media screen and (max-width: 430px) {
    background-image: ${(props) =>
      props.supportsWebP
        ? `url('../assets/mobile/screen-four.webp')`
        : `url('../assets/mobile/screen-four.png')`};
    background-position: center;
  }
`;

const ScreenFour = (props) => {
  const { t } = useTranslation();
  const isWebp = useSelector(selectWebp);
  const eventsData = useSelector(selectEventsData);

  useEffect(
    () => sendAnalyticsEvents(EVENTS.screen4Shown, eventsData),
    [eventsData]
  );

  return (
    <ScreenContainer supportsWebP={isWebp}>
      <ProgrBar now={100} />
      <ScreenContent
        title={t('crash_detection')}
        text={t('screen_4_text')}
        btn={t('continue')}
        onClick={() => props.history.push('/signup')}
      />
    </ScreenContainer>
  );
};

export default ScreenFour;
