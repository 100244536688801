import { createSlice } from '@reduxjs/toolkit';

export const uiSlice = createSlice({
  name: 'ui',
  initialState: {
    isWebp: false,
    loadImages: false,
  },
  reducers: {
    setWebp: (state, action) => {
      state.isWebp = action.payload;
      state.loadImages = true;
    },
  },
});

export const { setWebp } = uiSlice.actions;

export const selectWebp = (state) => state.ui.isWebp;
export const selectLoad = (state) => state.ui.loadImages;

export default uiSlice.reducer;
