import { pushGoogleTag, pushFBEvent } from './common';
import { sendFirebaseEvent } from './firebase';

export const PLANS = {
  'gz-6-month-panther': {
    name: 'gz-6-month-panther',
    price: 71.76,
    periodPrice: 71.76,
    description: 'six months',
    productId: '6 months - $71.76',
    duration: '6 months',
  },

  'gz-3-month-fox': {
    name: 'gz-3-month-fox',
    price: 59.88,
    periodPrice: 59.88,
    description: 'three months',
    productId: '3 months - $59.88',
    duration: '3 months',
  },

  'gz-1-month-tiger': {
    name: 'gz-1-month-tiger',
    price: 39.96,
    periodPrice: 39.96,
    description: 'one month',
    productId: '1 month - $39.96',
    duration: '1 month',
  },
};

const UPSELL_PRODUCT = {
  name: 'gz-monthly-driver-sos-skovoroda',
  price: 9.99,
  quantity: 1,
};

export const setMetrics = (
  plan,
  id,
  finalPrice,
  isUpsell = false,
  referer = '',
  eventId,
  gclid
) => {
  const price = finalPrice || PLANS[plan].periodPrice;
  const upsellPrice = UPSELL_PRODUCT.price;

  const items = isUpsell
    ? [
        {
          item_name: PLANS[plan].name,
          item_id: PLANS[plan].name,
          item_price: price,
          quantity: 1,
          item_coupon: '',
        },
        {
          item_name: UPSELL_PRODUCT.name,
          item_id: UPSELL_PRODUCT.name,
          item_price: upsellPrice,
          quantity: 1,
          item_coupon: '',
        },
      ]
    : [
        {
          item_name: PLANS[plan].name,
          item_id: PLANS[plan].name,
          item_price: price,
          quantity: 1,
          item_coupon: '',
        },
      ];

  const gaData = {
    transaction_id: id,
    affiliation: 'Onboarding Store',
    value: isUpsell ? upsellPrice : price,
    tax: 0,
    shipping: 0,
    currency: 'USD',
    coupon: '',
    gclid,
    referer,
    items,
  };

  const setFbData = (type) => ({
    value: type === 'upsell' ? upsellPrice : price,
    currency: 'USD',
    content_type: type,
    content_name: referer,
    subscription_sid: id,
  });

  const fbData = setFbData('paid_subscription');
  const upsellFbData = setFbData('upsell');

  pushFBEvent(fbData, eventId);
  sendFirebaseEvent('purchase', gaData);
  pushGoogleTag('purchase', gaData);
  if (isUpsell) {
    pushFBEvent(upsellFbData, `${eventId}_upsell`);
  }
};
