import React from 'react';
import ReactDOM from 'react-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { Provider } from 'react-redux';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from './App';
import store from './app/store';
import * as serviceWorker from './serviceWorker';
import { initAmplitude } from './analytics/amplitude';
import { initGismart } from './analytics/gismart';
import { initLockerStudio } from './analytics/lockerStudio';
// import { initFirebase } from './analytics/firebase';

const initCallback = (analytics) => {
  window.sessionStorage?.setItem(`${analytics}Init`, 'true');
};

initAmplitude(null, initCallback);
// initFirebase();

const checkCookies = setInterval(() => {
  const id = Cookies.get('userUuid');
  if (id) {
    initGismart(id, initCallback);
    initLockerStudio(id, initCallback);
    clearInterval(checkCookies);
  }
}, 500);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_KEY,
  integrations: [new BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
});

const persistor = persistStore(store);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
          <App />
        </Sentry.ErrorBoundary>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
