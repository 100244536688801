import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ar from './locales/ar/translations.json';
import de from './locales/de/translations.json';
import en from './locales/en/translations.json';
import es from './locales/es/translations.json';
import fr from './locales/fr/translations.json';
import it from './locales/it/translations.json';
import ja from './locales/ja/translations.json';
import ko from './locales/ko/translations.json';
import pt from './locales/pt/translations.json';
import ru from './locales/ru/translations.json';
import tr from './locales/tr/translations.json';
import uk from './locales/uk/translations.json';
import zh from './locales/zh/translations.json';

i18n.use(initReactI18next).init({
  fallbackLng: 'en',
  lng: navigator.language || navigator.userLanguage,
  resources: {
    ar: {
      translation: ar,
    },
    de: {
      translation: de,
    },
    en: {
      translation: en,
    },
    es: {
      translation: es,
    },
    fr: {
      translation: fr,
    },
    it: {
      translation: it,
    },
    ja: {
      translation: ja,
    },
    ko: {
      translation: ko,
    },
    pt: {
      translation: pt,
    },
    ru: {
      translation: ru,
    },
    tr: {
      translation: tr,
    },
    uk: {
      translation: uk,
    },
    zh: {
      translation: zh,
    },
  },
  interpolation: {
    escapeValue: false,
  },

  wait: true,
});

export default i18n;
