import { createSlice } from '@reduxjs/toolkit';
import { fetchWrapper } from '../../helper/utils';
import { setError } from '../error/errorSlice';
import { setLoader } from '../loader/loaderSlice';
import { setAnalyticsUserId } from '../../analytics/common';
import { setAmplitudeUserProperties } from '../../analytics/amplitude';
import { setEventData } from '../events/eventsSlice';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

const { REACT_APP_API_URL1 } = process.env;
const { REACT_APP_API_URL2 } = process.env;
const { REACT_APP_STRIPE_PK } = process.env;
const { REACT_APP_STRIPE_NAME } = process.env;

export const signupSlice = createSlice({
  name: 'signup',
  initialState: {
    email: '',
    isValid: false,
    token: null,
    gzUserID: null,
    isSuccess: null,
    userUuid: null,
    stripePublishKey: REACT_APP_STRIPE_PK,
    stripeAccountName: REACT_APP_STRIPE_NAME,
    stripePublishKey2: REACT_APP_STRIPE_PK,
    stripeAccountName2: REACT_APP_STRIPE_NAME,
    skipPaywallDisabled: false,
    btToken: null,
    availablePayments: {
      stripe: [],
      braintree: [],
    },
    paymentProvider: null,
    paymentSettingsStatus: null,
  },
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload.email;
      state.isValid = action.payload.isValid;
    },
    setRequestResult: (state, action) => {
      state.token = action.payload.token;
      state.isSuccess = action.payload.isSuccess;
      state.gzUserID = action.payload.gzUserID;
    },
    setUserToken: (state, action) => {
      state.token = action.payload;
    },
    setBtToken: (state, action) => {
      state.btToken = action.payload;
    },
    setUserUuid: (state, action) => {
      state.userUuid = action.payload;
    },
    setStripeData: (state, action) => {
      state.stripePublishKey = action.payload.stripePublishKey;
      state.stripeAccountName = action.payload.stripeAccountName;
    },
    setAlternativeStripeData: (state, action) => {
      state.stripePublishKey2 = action.payload['stripe_publishable_key'];
      state.stripeAccountName2 = action.payload['stripe_account_name'];
    },
    setAlternativeStripeDataAsMain: (state, action) => {
      state.stripePublishKey = state.stripePublishKey2;
      state.stripeAccountName = state.stripeAccountName2;
    },
    setSkipPaywallDisabled: (state, action) => {
      state.skipPaywallDisabled = action.payload;
    },
    setAvailablePayments: (state, action) => {
      state.availablePayments = action.payload;
    },
    setPaymentProvider: (state, action) => {
      state.paymentProvider = action.payload;
    },
    setPaymentSettingStatus: (state, action) => {
      state.paymentSettingsStatus = action.payload;
    },
  },
});

export const {
  setEmail,
  setRequestResult,
  setUserToken,
  setUserUuid,
  setStripeData,
  setAlternativeStripeData,
  setAlternativeStripeDataAsMain,
  setSkipPaywallDisabled,
  setBtToken,
  setAvailablePayments,
  setPaymentProvider,
  setPaymentSettingStatus,
} = signupSlice.actions;

export const sendAPIRequest =
  ({ email, name, landingType, userUuid }) =>
  (dispatch) => {
    dispatch(setLoader(true));

    const params = {
      registration_source: 'email',
      name: name || email,
      email,
      landing_type: landingType,
      user_uuid: userUuid,
    };

    fetchWrapper(`${REACT_APP_API_URL1}/signup`, { body: params })
      .then((data) => {
        const token = data.access_token;
        window.sessionStorage.setItem('access_token', token);
        dispatch(
          setRequestResult({
            token,
            gzUserID: data.entity.geozilla_user_id,
            isSuccess: true,
          })
        );
        dispatch(getPaymentSettings(token));
        setAnalyticsUserId(data.entity.geozilla_user_id);
      })
      .catch((error) => {
        dispatch(
          setError({
            show: true,
            text: error.errors?.info?.email[0] || 'Failed to create an account',
            type: 'signup',
          })
        );
        dispatch(setRequestResult({ isSuccess: false }));
      })
      .then(() => dispatch(setLoader(false)));
  };

const getPaymentSettings =
  (token, landingType, attemptNum = 0) =>
  (dispatch) => {
    let stripeParams = {
        stripePublishKey: REACT_APP_STRIPE_PK,
        stripeAccountName: REACT_APP_STRIPE_NAME,
      },
      paymentGateway = null,
      status = null;

    fetchWrapper(`${REACT_APP_API_URL2}/users/me/payments-settings`, {
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((data) => {
        const {
          stripe_publishable_key,
          stripe_account_name,
          braintree_client_token,
          alternative_stripe_account,
          available_payment_methods,
          payment_gateway,
          experiments,
        } = data;

        paymentGateway =
          braintree_client_token && payment_gateway
            ? payment_gateway
            : 'stripe';

        dispatch(setBtToken(braintree_client_token || null));
        status = 'success';

        if (available_payment_methods) {
          dispatch(
            setAvailablePayments({
              stripe: available_payment_methods?.stripe,
              braintree: available_payment_methods?.braintree,
            })
          );
        }

        if (alternative_stripe_account) {
          dispatch(setAlternativeStripeData(alternative_stripe_account));
        }
        if (experiments?.force_3ds_for_eu_customers) {
          setAmplitudeUserProperties({
            '3Ds_test': experiments?.force_3ds_for_eu_customers || null,
          });
        }
        const skipPaywall = experiments?.discount_skip_paywall;
        if (skipPaywall) {
          setAmplitudeUserProperties({ skip_Paywall: skipPaywall || null });
          dispatch(setSkipPaywallDisabled(!!skipPaywall));
        }
        if (stripe_publishable_key) {
          stripeParams = {
            stripePublishKey: stripe_publishable_key,
            stripeAccountName: stripe_account_name,
          };
        }
      })
      .catch((error) => {
        console.error(error);
        if (attemptNum < 2) {
          setTimeout(
            () => dispatch(getPaymentSettings(token, ++attemptNum)),
            1000
          );
        } else {
          status = 'fail';
          paymentGateway = 'stripe';
        }
      })
      .then(() => {
        dispatch(setStripeData(stripeParams));
        dispatch(setPaymentProvider(paymentGateway));
        dispatch(setPaymentSettingStatus(status));
      });
  };

export const setUuid = (userUuid) => (dispatch) => {
  const cookieUuid = Cookies.get('userUuid');

  if (!userUuid) {
    if (cookieUuid) {
      userUuid = cookieUuid;
    } else {
      userUuid = uuidv4();
    }
  }

  if (!cookieUuid) Cookies.set('userUuid', userUuid, { expires: 365 });
  dispatch(setUserUuid(userUuid));
  dispatch(setEventData({ id: 'userUuid', data: cookieUuid }));
};

export const selectEmail = (state) => state.signup.email;
export const selectValidity = (state) => state.signup.isValid;
export const selectSuccess = (state) => state.signup.isSuccess;
export const selectToken = (state) => state.signup.token;
export const selectUserID = (state) => state.signup.gzUserID;
export const selectUserUuid = (state) => state.signup.userUuid;
export const selectStripePublishKey = (state) => state.signup.stripePublishKey;
export const selectStripeAccountName = (state) =>
  state.signup.stripeAccountName;
export const selectStripePublishKey2 = (state) =>
  state.signup.stripePublishKey2;
export const selectStripeAccountName2 = (state) =>
  state.signup.stripeAccountName2;
export const selectSkipPaywallDisabled = (state) =>
  state.signup.skipPaywallDisabled;
export const selectBtToken = (state) => state.signup.btToken;
export const selectAvailablePayments = (state) =>
  state.signup.availablePayments;
export const selectPaymentProvider = (state) => state.signup.paymentProvider;
export const selectPaymentSettingsStatus = (state) =>
  state.signup.paymentSettingsStatus;

export default signupSlice.reducer;
