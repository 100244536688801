export const EVENTS = {
  onboardingStarted: 'OnboardingStarted',
  screen2Shown: 'OnboardingCheckLocation',
  screen3Shown: 'OnboardingPlacesShown',
  screen4Shown: 'OnboardingDPShown',
  signupRequestSent: 'OnboardingAnimationShown',
  signupScreenShown: 'OnboardingEnterEmailShown',
  signupSuccess: 'AccountCreated',
  optionsScreenShown: 'OnboardingOptionsShown',
  plansScreenShown: 'OnboardingPaywallShown',
  onboardingPaywallChosen: 'OnboardingPaywallChosen',
  downloadScreenShown: 'OnboardingTryFreeShown',
  checkoutScreenShown: 'OnboardingPaymentShown',
  cardMethodChosen: 'CardCheckoutChosen',
  paypalMethodChosen: 'PaypalCheckoutChosen',
  successScreenShown: 'OnboardingSuccessShown',
  paymentDeclined: 'PaymentDeclinedInsufFunds',
  onboardingDeclineTryAgain: 'PaymentDeclineTryAgain',
  subBuyTapped: 'SubBuyTapped',
  paymentAnimationScreen: 'PaymentAnimationScreen',
  subBuyFailed: 'SubsPurchaseFailed',
  subBuyClosed: 'PaywallClosed',
  creatingProfileAnim: 'CreatingProfileAnim',
  subscribed: 'PaywallSubscribed',
};
