import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { fontSize } from '../../mixins';
import {
  selectLandingType,
  selectPlan,
  setPlan,
  setDefaultPlanId,
  setUpsellPlan,
} from './plansSlice';

const StyledLi = styled.li`
  background: ${(props) => (props.active ? '#FBA253' : 'transparent')};
  border-radius: 11px;
  width: 33%;
  max-width: 106px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  & > span {
    text-transform: uppercase;
    text-align: center;
    letter-spacing: -0.2px;
    font-weight: 800;
    color: #ffffff;
    mix-blend-mode: normal;
    ${fontSize(14)};
    line-height: 16px;
    padding: ${({ active, cropBorder }) => (active && cropBorder ? 7 : 1)}px 0;
  }
`;

const StyledOffer = styled.button`
  width: calc(100% - 4px);
  height: 180px;
  margin-bottom: 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  color: #323232;
  background: #ffffff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 0 8px;
  text-align: center;
  position: relative;
  z-index: 90;
  border: none;
  overflow: hidden;

  &:focus {
    outline: none;
  }

  b {
    ${fontSize(34)};
    line-height: 40px;
    font-weight: 800;
    margin-bottom: 6px;
  }
`;

const RibbonWrapper = styled.div`
  width: 103px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: 0;
  right: 0;

  div {
    position: absolute;
    padding: 5px 10px;
    right: -10px;
    width: 120px;
    background: #00a08b;
    ${fontSize(8)};
    line-height: 10px;
    color: #ffffff;
    mix-blend-mode: normal;
    text-align: center;
    letter-spacing: 1px;
    font-weight: 600;
  }
`;

const StyledList = styled.ul`
  width: 100%;
  max-width: 345px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0;
  margin: 0 0 16px;
  list-style: none;
  align-items: flex-end;
  min-height: 230px;
`;

const StyledPrice = styled.p`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 13px 0 20px;
  padding-top: 8px;
  border-top: 1px solid
    ${({ empty }) => (empty ? 'transparent' : 'rgba(132,132,132,.4)')};
  width: 100%;

  span {
    color: #fba253;
    ${fontSize(16)};
    line-height: 20px;
    min-height: 20px;
    mix-blend-mode: normal;
    opacity: 0.9;
    font-weight: 300;
    letter-spacing: unset;

    &:last-child {
      ${fontSize(12)};
      line-height: 16px;
      min-height: 16px;
    }
  }
`;

const StyledPeriod = styled.span`
  ${fontSize(14)};
  line-height: 17px;
`;

const DefaultPlans = () => {
  const { t } = useTranslation();
  const plan = useSelector(selectPlan);
  const dispatch = useDispatch();
  const landingType = useSelector(selectLandingType);

  const defaultPaywall = useMemo(
    () => [
      {
        id: 'gz-6-month-panther',
        title: '6',
        period: t('months'),
        price: '$2.99',
        bestValue: true,
        label: `${t('save')} 76%`,
        week: t('per_week'),
      },
      {
        id: 'gz-3-month-fox',
        title: '3',
        period: t('months'),
        price: '$4.99',
        label: `${t('save')} 52%`,
        week: t('per_week'),
      },
      {
        id: 'gz-1-month-tiger',
        title: '1',
        period: t('month'),
        price: '$9.99',
        week: t('per_week'),
      },
    ],
    [t]
  );

  const OFFERS = useMemo(
    () => ({
      fullPrice: defaultPaywall,
    }),
    [defaultPaywall]
  );

  const currentPlans = useMemo(
    () => OFFERS[landingType],
    [landingType, OFFERS]
  );

  useEffect(() => {
    dispatch(setPlan(currentPlans[0].id));
    dispatch(setDefaultPlanId(currentPlans[0].id));
    dispatch(setUpsellPlan(currentPlans[0].upsellPlan || ''));
  }, [dispatch, currentPlans]);

  const onPlanClick = (id, upsellPlan) => {
    dispatch(setPlan(id));
    upsellPlan !== '' && dispatch(setUpsellPlan(upsellPlan));
  };

  const renderOffer = (item, i) => {
    const {
      id,
      label,
      bestValue,
      title,
      period,
      price,
      week,
      upsellPlan = '',
    } = item;
    const isActive = plan === id;

    return (
      <StyledLi active={isActive} cropBorder={!!label} key={item.id}>
        <span>{isActive && label}</span>

        <StyledOffer
          key={id}
          onClick={() => onPlanClick(id, upsellPlan)}
          selected={isActive}
          id={`plan-button-${i + 1}`}
        >
          {bestValue && (
            <RibbonWrapper>
              <div>{t('best_value')}</div>
            </RibbonWrapper>
          )}
          <b>{title}</b>
          <StyledPeriod>{period}</StyledPeriod>
          <StyledPrice empty={!price}>
            <span>{price}</span>
            <span>{week}</span>
          </StyledPrice>
        </StyledOffer>
      </StyledLi>
    );
  };

  return (
    <>
      <StyledList>{currentPlans.map(renderOffer)}</StyledList>
    </>
  );
};

export default DefaultPlans;
