export const setAFCustomerId = (userId) => {
  if (userId) {
    const CUID = userId.toString();
    return window.AF('pba', 'setCustomerUserId', `'${CUID}'`);
  }

  return null;
};

export const sendAFEvent = (eventName, eventParams) => {
  if (window.AF) {
    return window.AF('pba', 'event', {
      eventType: 'EVENT',
      eventName,
      eventValue: eventParams,
    });
  }
};
