import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { isBrowser } from 'react-device-detect';
import { fontSize, wh, fadeInAnimation } from '../../mixins';
import { Visa, MasterCard, Amex } from './Icons';
import useDebounce from '../../helper/useDebounce';
import { setCardholderName, setFormValidity } from './checkoutSlice';
import { sendAnalyticsEvents } from '../../analytics/common';
import { EVENTS } from '../../analytics/events';
import { setError } from '../error/errorSlice';
import { selectEventsData, setEventDataList } from '../events/eventsSlice';
import { selectShowCheckout } from '../plans/plansSlice';
const isArabic = navigator.language.startsWith('ar');
const textAlign = isArabic ? 'right' : 'left';

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  position: relative;
  z-index: 10;
  margin: 0;
  ${isBrowser && fadeInAnimation};

  & > * {
    position: relative;
    z-index: 10;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    ${wh('90%')};
    position: absolute;
    z-index: -5;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #e9e9e9;
    border-radius: inherit;
    box-shadow: inherit;
  }

  &:after {
    ${wh('80%')};
    top: -20px;
    background-color: #d4d4d4;
    z-index: -6;
    box-shadow: none;
  }
`;

const StyledCard = styled.div`
  width: 100%;
  min-height: 222px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: ${isArabic ? 'flex-end' : 'flex-start'};
  position: relative;
  background-color: #fff;
  z-index: 10;

  & > * {
    position: relative;
    z-index: 10;
  }
`;

const StyledFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledLabel = styled.span`
  color: #323232;
  ${fontSize(14)};
  line-height: 18px;
  text-align: ${textAlign};
  font-weight: 300;
  padding: 7px 0;
`;

const StyledInput = styled.input`
  ${wh('100%', '28px')};
  color: #00a08b;
  padding: 3px 8px 3px 0;
  background-color: transparent;
  border: none;
  ${fontSize(18)};
  line-height: 22px;
  font-weight: 300;
  text-align: ${textAlign};

  &::-webkit-credit-card-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }

  &::placeholder {
    color: #adadad;
    letter-spacing: 1.2px;
    font-weight: 300;
    ${fontSize(18)};
    line-height: 22px;
  }

  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
`;

const StyledBrandIcon = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
  left: 16px;
  ${textAlign}: auto;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    height: auto;
  }
`;

const Stripe = ({ card, cvc, expiration, inputRef }) => {
  const { t } = useTranslation();
  const [cardBrand, setCardBrand] = useState('');
  const [name, setName] = useState('');
  const [isCvv, setIsCvv] = useState(null);
  const [isExp, setIsExp] = useState(null);
  const [isCard, setIsCard] = useState(null);
  const [err, setErr] = useState('');
  const [isValid, setIsValid] = useState(false);
  const dispatch = useDispatch();
  const eventsData = useSelector(selectEventsData);

  const debouncedName = useDebounce(name, 500);
  const debouncedErr = useDebounce(err, 500);
  const debouncedValidity = useDebounce(isValid, 500);
  const showCheckout = useSelector(selectShowCheckout);

  card.on('ready', () => {
    document.querySelectorAll('.__PrivateStripeElement-input').forEach((el) => {
      el.setAttribute('inputmode', 'numeric');
    });
    card.focus();
  });

  useEffect(() => {
    if (showCheckout) {
      card.focus();
    }
  }, [showCheckout, card]);

  card.on('change', (event) => {
    if (event.brand && event.brand !== 'unknown' && event.brand !== cardBrand) {
      setCardBrand(event.brand);
    }
    if (event.empty) {
      setCardBrand('');
    }
    event.complete && expiration.focus();
    fieldEventHandler(event, setIsCard);
  });

  expiration.on('change', (event) => {
    event.complete && cvc.focus();
    fieldEventHandler(event, setIsExp);
  });

  cvc.on('change', (event) => {
    event.complete && inputRef.current?.focus();
    fieldEventHandler(event, setIsCvv);
  });

  useEffect(() => {
    card.mount('#card-number-element');
    expiration.mount('#card-expiry-element');
    cvc.mount('#card-cvc-element');
  }, [card, cvc, expiration]);

  useEffect(() => {
    if (cardBrand && eventsData && eventsData.card_brand !== cardBrand) {
      const localEventData = {
        card_brand: cardBrand,
        cardBrand: cardBrand,
      };
      dispatch(setEventDataList(localEventData));
      sendAnalyticsEvents(EVENTS.cardMethodChosen, {
        ...eventsData,
        ...localEventData,
      }); // Analytics event for starting typing the card number
    }
  }, [cardBrand, eventsData, dispatch]);

  const fieldEventHandler = (evt, setValidity) => {
    if (evt.error) {
      setErr(evt.error.message);
    }
    setValidity(evt.complete);
  };

  useEffect(() => {
    if (isCard && isCvv && isExp) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }
  }, [isCard, isCvv, isExp, dispatch]);

  useEffect(() => {
    if (debouncedName) {
      dispatch(setCardholderName(debouncedName));
    }
  }, [debouncedName, dispatch]);

  useEffect(() => {
    if (debouncedErr) {
      dispatch(
        setError({ show: true, type: 'cardValidation', text: debouncedErr })
      );
    }
  }, [debouncedErr, dispatch]);

  useEffect(() => {
    dispatch(setFormValidity(debouncedValidity));
  }, [debouncedValidity, dispatch]);

  let brandIcon;

  switch (cardBrand) {
    case 'visa':
      brandIcon = Visa;
      break;
    case 'mastercard':
      brandIcon = MasterCard;
      break;
    case 'amex':
      brandIcon = Amex;
      break;
    default:
      brandIcon = null;
  }

  return (
    <StyledWrapper>
      <StyledCard>
        <StyledFieldWrapper>
          <StyledLabel>{t('card_number')}</StyledLabel>
          <div id={'card-number-element'} />
        </StyledFieldWrapper>

        <StyledRow>
          <StyledFieldWrapper>
            <StyledLabel>{t('MM/PP')}</StyledLabel>
            <div id={'card-expiry-element'} />
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <StyledLabel>{t('CVC2')}</StyledLabel>
            <div id={'card-cvc-element'} />
          </StyledFieldWrapper>
        </StyledRow>

        <StyledFieldWrapper>
          <StyledLabel>{t('cardholder_name')}</StyledLabel>
          <StyledInput
            ref={inputRef}
            onChange={(e) => setName(e.target.value)}
            type="text"
            autocomplete="cc-name"
            placeholder={t('name')}
          />
        </StyledFieldWrapper>

        <StyledBrandIcon>{brandIcon}</StyledBrandIcon>
      </StyledCard>
    </StyledWrapper>
  );
};

export default Stripe;
