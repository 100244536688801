import { createSlice } from '@reduxjs/toolkit';
import { queryParser } from '../../helper/utils';

export const plansSlice = createSlice({
  name: 'plans',
  initialState: {
    showCheckout: false,
    defaultPlanId: '',
    landingType: 'fullPrice',
    plan: null,
    checkoutVisited: null,
    origin: '',
    utm: {},
    os: '',
    upsellPlan: '',
  },
  reducers: {
    setDefaultPlanId: (state, action) => {
      state.defaultPlanId = action.payload;
    },
    setShowCheckout: (state, action) => {
      state.showCheckout = action.payload;
    },
    setLandingType: (state, action) => {
      state.landingType = action.payload || 'fullPrice';
    },
    setPlan: (state, action) => {
      state.plan = action.payload;
    },
    setCheckoutVisited: (state, action) => {
      state.checkoutVisited = action.payload;
    },
    setUTM: (state, action) => {
      state.utm = queryParser(action.payload);
    },
    setOS: (state, action) => {
      state.os = action.payload;
    },
    setUpsellPlan: (state, action) => {
      state.upsellPlan = action.payload;
    },
  },
});

export const {
  setPlan,
  setLandingType,
  setCheckoutVisited,
  setUTM,
  setOS,
  setShowCheckout,
  setDefaultPlanId,
  setUpsellPlan,
} = plansSlice.actions;

export const selectShowCheckout = (state) => state.plans.showCheckout;
export const selectDefaultPlanId = (state) => state.plans.defaultPlanId;
export const selectPlan = (state) => state.plans.plan;
export const selectLandingType = (state) => state.plans.landingType;
export const selectIfCheckoutVisited = (state) => state.plans.checkoutVisited;
export const selectUTM = (state) => state.plans.utm;
export const selectOS = (state) => state.plans.os;
export const selectUpsellPlan = (state) => state.plans.upsellPlan;

export default plansSlice.reducer;
