import React from 'react';
import styled from 'styled-components';
import { wh, fontSize } from '../mixins';

const Button = styled.button`
  ${wh('90%', 'auto')};
  max-width: 327px;
  background: ${(props) => props.customColor || '#F09A51'};
  color: #ffffff;
  ${fontSize(14)};
  line-height: 18px;
  font-weight: 500;
  padding: 14px 20px;
  border: none;
  border-radius: 24px;
  position: relative;
  z-index: ${(props) => (props.zIndex ? props.zIndex : 'initial')};

  ${(props) =>
    props.mobileOnly
      ? '@media screen and (min-width: 768px) {display: none;}'
      : null};

  &:focus {
    outline: none;
  }

  &:hover {
    background: ${(props) => props.customHover || 'rgba(241, 163, 95, 1)'};
  }

  &:active {
    background: ${(props) => props.customActive || 'rgba(206, 106, 18, 1)'};
  }

  &:disabled {
    opacity: 0.6;

    &:hover {
      background: ${(props) => props.customColor || '#F09A51'};
    }
  }
`;

const UIButton = ({
  onClick,
  children,
  disabled,
  mobileOnly,
  customColor,
  customHover,
  customActive,
  zIndex,
  autoFocus,
  tabIndex,
  customId,
}) => (
  <Button
    mobileOnly={mobileOnly}
    onClick={onClick}
    disabled={disabled}
    customColor={customColor}
    customHover={customHover}
    customActive={customActive}
    autoFocus={autoFocus}
    tabIndex={tabIndex}
    zIndex={zIndex}
    id={customId || 'continue-button'}
  >
    {children}
  </Button>
);

export default UIButton;
