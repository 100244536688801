import React from 'react';
import styled from 'styled-components';

const StyledBack = styled.button`
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 6px;
  top: 12px;
  left: 2%;
  border: none;
  background-color: transparent;
  z-index: 100;
  cursor: pointer;

  @media screen and (min-width: 767px) {
    top: 10vh;
    left: 10vw;
  }

  svg {
    width: 100%;
  }

  &:focus {
    outline: none;
  }
`;

const BackButton = ({ analyticsEvent }) => {
  const onClick = () => {
    analyticsEvent && analyticsEvent();
  };

  return (
    <StyledBack {...{ onClick }} id={'come-back-button'}>
      <svg
        width="16"
        height="14"
        viewBox="0 0 16 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15 5.99997H3.135L6.768 1.63997C7.122 1.21597 7.064 0.58497 6.64 0.23197C6.215 -0.12203 5.585 -0.0640299 5.232 0.35997L0.232 6.35997C0.193 6.40697 0.173 6.46197 0.144 6.51397C0.12 6.55597 0.091 6.59197 0.073 6.63797C0.028 6.75297 0.000999928 6.87397 0.000999928 6.99597L0 6.99997L0.000999928 7.00397C0.000999928 7.12597 0.028 7.24697 0.073 7.36197C0.091 7.40797 0.12 7.44397 0.144 7.48597C0.173 7.53797 0.193 7.59297 0.232 7.63997L5.232 13.64C5.43 13.877 5.714 14 6 14C6.226 14 6.453 13.924 6.64 13.768C7.064 13.415 7.122 12.784 6.768 12.36L3.135 7.99997H15C15.552 7.99997 16 7.55197 16 6.99997C16 6.44797 15.552 5.99997 15 5.99997Z"
          fill={'#ffffff'}
        />
      </svg>
    </StyledBack>
  );
};

export default BackButton;
