import { createGlobalStyle } from 'styled-components';
import { fontSize } from './mixins';

const GlobalStyle = createGlobalStyle`

@font-face {
  font-family: 'Open Sans';
  src: url("/public/fonts/Open_Sans/OpenSans-Bold.ttf");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url("/public/fonts/Open_Sans/OpenSans-ExtraBold.ttf");
  font-style: normal;
  font-weight: 800;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url("/public/fonts/Open_Sans/OpenSans-Light.ttf");
  font-style: normal;
  font-weight: 300;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url("/public/fonts/Open_Sans/OpenSans-Regular.ttf");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url("/public/fonts/Open_Sans/OpenSans-SemiBold.ttf");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: 'Open Sans';
  src: url("/public/fonts/Open_Sans/OpenSans-Bold.ttf");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

html {
  min-height: 100%;
}

 body {
    margin: 0;
    padding: 0;
    width: 100%;
    min-height: 100vh;
    display: flex;
    flex-wrap: wrap;
    position: relative;
    background-image: linear-gradient(180deg, #00B9A0 0%, #00564A 100%);
    background-size: cover;
    background-repeat: no-repeat;
    font-family: Open-Sans, sans-Serif;
    
    > div {
      width: 100%;
    }
  }
  
  * {
    box-sizing: border-box;
  }
  
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="password"],
  input[type="tel"],
  input[type="textarea"],
  input[type="radio"],
  textarea,
  input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  }
  
  input[type="radio"] {
    display: none;
  }
 
  
  a, input, button {
     font-family: Open-Sans, sans-Serif;
  }
  
  a {
    color: #fff;
     
      &:hover,
      &:active {
      color: #fff;
    }
  }
  
  ol.carousel-indicators li {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgba(50, 50, 50, 0.4);
    
    &.active {
      background-color: #FBA253;
    }
  }
  
  div:focus-visible {
    outline: none !important;
  }
  
    //Overriding phone input styles
    
  .react-tel-input .form-control {
    @media screen and (min-width: 767px) {
      width: 420px !important;
    }
  }
    
 .country-list {
      box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1) !important;
      border-radius: 10px !important;
      
       @media screen and (min-width: 767px) {
         width: 420px !important;
     }
 }
 
  .country.highlight,
  .country:hover {
    background-color: rgba(0, 191, 165, 0.2) !important;
  }
  
  .react-tel-input .selected-flag:focus:before, .react-tel-input .selected-flag.open:before {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
  }
  
  .react-tel-input .selected-flag:before {
    border: none !important;
  }
  
 .selected-flag.open:before {
    outline: none !important;
    border: none !important;
    box-shadow: none !important;
 }
 
 .search {
    position: relative;
    
    &::before {
      content: '';
      width: 24px;
      height: 24px;
      background-image: url('../assets/search-icon.svg');
      background-position: 0;
      background-size: contain;
      position: absolute;
      top: 13px;
      left: 14px;
      background-repeat: no-repeat;
    }
 }
 
 .search-box {
   border: none !important;
   padding: 7px 0 0 32px !important;
 }
 
 .form-control {
   border: 1px solid #D8D8D8 !important;
   border-radius: 4px !important;
   
   &::placeholder {
    ${fontSize(14)};
    line-height: 16px;
    color: #9C9C9C;
    letter-spacing: 0.2px;
   }
 }
 
 .slick-dots {
   .slick-active > div > div {
     background-color: #02E5B7;
     border-color: #02E5B7
   }
 }
 
 .react-tel-input .form-control {
  width: 100% !important;
 }

`;

export default GlobalStyle;
