import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector, useDispatch } from 'react-redux';
import { isBrowser } from 'react-device-detect';
import Logo from '../../UI/Logo';
import UIButton from '../../UI/UIButton';
import {
  wh,
  MediumTitle,
  fadeInAnimation,
  screenPadding,
  fontSize,
  centeredFlex,
} from '../../mixins';
import SignupInput from './SignupInput';
import {
  selectEmail,
  selectSuccess,
  selectUserUuid,
  selectValidity,
  sendAPIRequest,
} from './signupSlice';
import { sendAnalyticsEvents } from '../../analytics/common';
import { EVENTS } from '../../analytics/events';
import { selectEventsData } from '../events/eventsSlice';
import { selectLandingType } from '../plans/plansSlice';
import {
  appendLink,
  checkoutImagesLinks,
  checkoutWebpImagesLinks,
  plansImagesLinks,
  plansWebpImagesLinks,
} from '../../helper/utils';
import { selectWebp } from '../ui/uiSlice';

const StyledSignup = styled.div`
  ${centeredFlex};
  ${screenPadding};
  min-height: 60%;
  ${isBrowser && fadeInAnimation};
  justify-content: flex-end;

  svg {
    margin-bottom: 50px;
  }
`;

const Title = styled.h1`
  ${MediumTitle};
`;

export const StyledBtn = styled.button`
  background-color: transparent;
  border: none;
  display: inline-block;
  color: #fff;
  cursor: pointer;
  ${fontSize(12)};
  line-height: 18px;
  letter-spacing: -0.128px;
  text-decoration: underline;
  font-weight: 300;

  &:focus,
  &:active {
    outline: none;
  }
`;

const StyledText = styled.p`
  position: absolute;
  bottom: 14px;
  ${fontSize(12)};
  ${wh('256px', 'auto')};
  line-height: 18px;
  color: #fff;
  letter-spacing: -0.128px;
  text-align: center;
  font-weight: 300;
  margin: 0 0 20px;

  a {
    text-decoration: underline;
  }
`;

const ContentBlock = styled.div`
  ${centeredFlex};
  width: 100%;
`;

const SignupScreen = ({ history }) => {
  const { t } = useTranslation();
  const isValid = useSelector(selectValidity);
  const email = useSelector(selectEmail);
  const isSuccess = useSelector(selectSuccess);
  const dispatch = useDispatch();
  const eventsData = useSelector(selectEventsData);
  const landingType = useSelector(selectLandingType);
  const isWebp = useSelector(selectWebp);
  const userUuid = useSelector(selectUserUuid);
  const [initEventSent, setInitEventStatus] = useState(false);

  const onBtnClick = () => {
    sendAnalyticsEvents(EVENTS.signupRequestSent, eventsData);
    dispatch(sendAPIRequest({ email, landingType, userUuid }));
  };

  useEffect(() => {
    isWebp
      ? checkoutWebpImagesLinks.map((href) => appendLink(href)) &&
        plansWebpImagesLinks.map((href) => appendLink(href))
      : plansImagesLinks.map((href) => appendLink(href)) &&
        checkoutImagesLinks.map((href) => appendLink(href));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (initEventSent) return;

    sendAnalyticsEvents(EVENTS.signupScreenShown, eventsData);
    setInitEventStatus(true);
  }, [initEventSent, eventsData]);

  useEffect(() => {
    if (isSuccess) {
      sendAnalyticsEvents(EVENTS.signupSuccess, eventsData);
      history.push('/signup/set-your-options');
    }
  }, [isSuccess, history, eventsData]);

  const onLinkClick = (url) => window.open(url);

  return (
    <StyledSignup>
      <Logo />
      <ContentBlock>
        <Title>{t('whats_your_email')}</Title>
        <SignupInput />
        <UIButton onClick={onBtnClick} disabled={!isValid}>
          {t('continue')}
        </UIButton>
        <StyledText>
          <span>{t('privacy_note')}</span>
          <StyledBtn
            onClick={() => onLinkClick('https://geozilla.com/terms-of-use/')}
            id={'link-to-terms'}
          >
            {t('terms')}
          </StyledBtn>
          <span>{t('and')}</span>
          <StyledBtn
            onClick={() => onLinkClick('https://geozilla.com/privacy-policy/')}
            id={'link-to-privacy'}
          >
            {t('privacy')}
          </StyledBtn>
        </StyledText>
      </ContentBlock>
    </StyledSignup>
  );
};

export default SignupScreen;
