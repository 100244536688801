import React from 'react';
import styled from 'styled-components';
import { fontSize, centeredFlex, screenPadding, wh } from '../mixins';
import UIButton from './UIButton';

const StyledTitle1 = styled.h1`
  ${fontSize(34)};
  line-height: 36px;
  color: #fff;
  text-align: center;
  letter-spacing: 0.5px;
  margin-top: 0px;
  margin-bottom: ${(props) => (props.longText ? '16px' : '22px')};
`;

const StyledText = styled.p`
  ${fontSize(14)};
  line-height: 24px;
  color: #fff;
  text-align: center;
  letter-spacing: -0.183529px;
  margin: 0;
  margin-bottom: ${(props) => (props.longText ? '16px' : '32px')};
  width: ${(props) => (props.longText ? '300px' : 'auto')};
  @media screen and (max-width: 430px) {
    width: ${(props) => (props.longText ? '188px' : 'auto')};
  }
`;

const StyledContainer = styled.div`
  ${wh('100%', 'auto')};
  ${centeredFlex};
  ${screenPadding};
  margin-top: 550px;
  @media screen and (max-height: 1024px) {
    margin-top: auto;
    position: static;
  }
`;

const ScreenContent = (props) => {
  const { title, text, btn, onClick, longText } = props;
  return (
    <StyledContainer>
      <StyledTitle1 longText={longText}>{title}</StyledTitle1>
      <StyledText longText={longText}>{text}</StyledText>
      <UIButton onClick={onClick}>{btn}</UIButton>
    </StyledContainer>
  );
};

export default ScreenContent;
