import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';
import Collapse from 'react-bootstrap/Collapse';
import { useDispatch } from 'react-redux';
import { wh, fontSize } from '../../mixins';
import { setAnsweredCount } from './optionsSlice';
import { setEventData } from '../events/eventsSlice';

const isArabic = navigator.language.startsWith('ar');
const textAlign = isArabic ? 'right' : 'left';

const fadeIn = keyframes`
 0% { opacity: 0; }
 100% { opacity: 1;}
`;

const StyledCard = styled.div`
  ${wh('100%', 'auto')};
  padding: 16px;
  background: #ffffff;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.180944);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 16px;
  text-align: ${textAlign};
`;

const StyledTitle = styled.p`
  width: 100%;
  font-weight: 500;
  ${fontSize(18)};
  line-height: 24px;
  color: #323232;
  margin: 8px 0;
`;

const StyledLabel = styled.label`
  width: 100%;
  font-weight: 300;
  ${fontSize(16)};
  line-height: 20px;
  color: #323232;
  position: relative;
  padding: 14px 0;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    width: calc(100% + 16px);
    height: 1px;
    background: #f0f0f0;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    &::after {
      content: none;
    }
  }
`;

const StyledList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const StyledSelected = styled.p`
  width: 100%;
  min-height: 18px;
  font-weight: 300;
  ${fontSize(16)};
  line-height: 18px;
  color: #323232;
  margin: 0;
  padding: 0;

  span {
    display: block;
    width: 100%;
    opacity: 0;
    animation: ${fadeIn} 0.6s forwards ease;
    background-image: url('../assets/checkmark.svg');
    background-size: contain;
    background-position: ${isArabic ? 'left' : 'right'} center;
    background-repeat: no-repeat;
  }
`;

const Card = ({ id, title, options }) => {
  const [open, setOpen] = useState(true);
  const [selected, setSelected] = useState('');
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const onRadioChange = (event) => {
    const {
      target: { value, id, dataset },
    } = event;
    setOpen(!open);
    setSelected(value);
    dispatch(setAnsweredCount(1));
    dispatch(setEventData({ id, data: dataset.value }));
  };

  const renderInput = (item, i) => (
    <StyledLabel key={i} id={`${id}-option${i + 1}`}>
      {item.name}
      <input
        type="radio"
        id={id}
        onChange={(event) => onRadioChange(event)}
        name={title}
        data-value={item.id}
        value={item.name}
      />
    </StyledLabel>
  );

  return (
    <StyledCard>
      <StyledTitle>{title}</StyledTitle>
      <StyledSelected>{show && <span>{selected}</span>}</StyledSelected>
      <Collapse in={open} appear timeout={300} onExited={() => setShow(true)}>
        <StyledList>{options.map(renderInput)}</StyledList>
      </Collapse>
    </StyledCard>
  );
};

export default Card;
