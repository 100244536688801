import amplitude from 'amplitude-js';

const API_KEY = process.env.REACT_APP_GISMART_KEY;

export const initGismart = (USER_ID, callback) => {
  amplitude.getInstance('gismart').init(
    API_KEY,
    USER_ID || null,
    {
      apiEndpoint: process.env.REACT_APP_GISMART_DOMAIN,
      headers: {
        Authorization: 'Bearer cHJveHktdXNlcjozQyk9Q3YncTd5L0A4Pjcp',
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      forceHttps: true,
    },
    () => {
      callback('gismart');
      sendInitGismartEvent(USER_ID);
    }
  );
};

export const sendGismartData = (EVENT_NAME, EVENT_PROPERTIES) => {
  const checkInit = setInterval(() => {
    if (window.sessionStorage.getItem('gismartInit') === 'true') {
      amplitude.getInstance('gismart').logEvent(EVENT_NAME, EVENT_PROPERTIES);
      clearInterval(checkInit);
    }
  }, 500);
};

const sendInitGismartEvent = (USER_ID) => {
  const eventData = {
    gia_id: API_KEY,
    public_id: USER_ID,
  };
  sendGismartData('publicid_giaid', eventData);
};
