import React, { useEffect, useRef } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import landingTypesList from './helper/landingTypes';
import Welcome from './features/welcome/Welcome';
import ScreenTwo from './features/welcome/ScreenTwo';
import ScreenThree from './features/welcome/ScreenThree';
import ScreenFour from './features/welcome/ScreenFour';
import SignupScreen from './features/signup/SignupScreen';
import OptionsScreen from './features/options/OptionsScreen';
import CreatingProfile from './features/creatingProfile/CreatingProfile';
import SuccessScreen from './features/success/SuccessScreen';
import ErrorScreen from './features/error/ErrorScreen';
import PlanCheckout from './features/PlanCheckout/PlanCheckout';

const Router = ({ history }) => {
  const routes = [
    {
      path: landingTypesList,
      component: Welcome,
    },
    {
      path: '/check-location',
      component: ScreenTwo,
    },
    {
      path: '/place-alerts',
      component: ScreenThree,
    },
    {
      path: '/crash-detection',
      component: ScreenFour,
    },
    {
      path: '/signup',
      component: SignupScreen,
    },
    {
      path: '/signup/set-your-options',
      component: OptionsScreen,
    },
    {
      path: '/signup/creating-your-profile',
      component: CreatingProfile,
    },
    {
      path: '/checkout',
      component: PlanCheckout,
    },
    {
      path: '/checkout/error',
      component: ErrorScreen,
    },
    {
      path: '/checkout/success',
      component: SuccessScreen,
    },
  ];

  const locationHistory = useRef([history.location.pathname]);

  useEffect(() => {
    history?.listen((newLocation, action) => {
      const { current } = locationHistory;
      let path = newLocation.pathname;
      const prevPath = current[current.length - 1];

      if (path !== prevPath) {
        if (action === 'PUSH') {
          if (!current.includes(path)) {
            locationHistory.current.push(path);
            window.sessionStorage.setItem(
              'locationHistory',
              locationHistory.current
            );
          } else {
            locationHistory.current = current.slice(
              0,
              current.indexOf(path) + 1
            );
            window.sessionStorage.setItem(
              'locationHistory',
              locationHistory.current
            );
          }
        } else {
          path = current[current.length - 1];
        }

        history.push({ pathname: path });
      }
    });
  }, [history]);

  return (
    <Switch>
      {routes.map((route, i) => (
        <Route exact key={i} {...route} />
      ))}
    </Switch>
  );
};

export default withRouter(Router);
