import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectPaymentError,
  setFormValidity,
  setPaymentError,
} from '../checkout/checkoutSlice';
import { selectEventsData } from '../events/eventsSlice';
import { sendAnalyticsEvents } from '../../analytics/common';
import { EVENTS } from '../../analytics/events';
import styled from 'styled-components';
import UIButton from '../../UI/UIButton';

const isArabic = navigator.language.startsWith('ar');
const textAlign = isArabic ? 'right' : 'left';

const ErrorIcon = (
  <svg
    width="80"
    height="76"
    viewBox="0 0 80 76"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M34.8726 8.43682C37.2095 4.59162 42.7905 4.59162 45.1274 8.43682L69.1008 47.8839C71.5309 51.8824 68.6524 57 63.9735 57H16.0266C11.3476 57 8.46915 51.8824 10.8992 47.8839L34.8726 8.43682Z"
      fill="#FF5B5B"
    />
    <path
      d="M40.0351 39.8922C39.5791 39.8922 39.2191 39.7482 38.9551 39.4602C38.7151 39.1722 38.5711 38.7642 38.5231 38.2362L37.4431 24.4842C37.3711 23.5962 37.5631 22.8882 38.0191 22.3602C38.4751 21.8082 39.1471 21.5322 40.0351 21.5322C40.8991 21.5322 41.5471 21.8082 41.9791 22.3602C42.4351 22.8882 42.6271 23.5962 42.5551 24.4842L41.4751 38.2362C41.4511 38.7642 41.3071 39.1722 41.0431 39.4602C40.8031 39.7482 40.4671 39.8922 40.0351 39.8922ZM40.0351 47.3802C39.2191 47.3802 38.5591 47.1282 38.0551 46.6242C37.5751 46.1202 37.3351 45.4722 37.3351 44.6802C37.3351 43.9122 37.5751 43.2882 38.0551 42.8082C38.5591 42.3042 39.2191 42.0522 40.0351 42.0522C40.8751 42.0522 41.5231 42.3042 41.9791 42.8082C42.4591 43.2882 42.6991 43.9122 42.6991 44.6802C42.6991 45.4722 42.4591 46.1202 41.9791 46.6242C41.5231 47.1282 40.8751 47.3802 40.0351 47.3802Z"
      fill="white"
    />
  </svg>
);

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

const Title = styled.h1`
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  margin-bottom: 8px;
`;

const ErrorText = styled.div`
  font-size: 14px;
  line-height: 17px;
  color: #b70000;
  text-align: center;
  margin-bottom: 16px;
`;

const StyledList = styled.ul`
  padding-${textAlign}: 30px;
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  ${isArabic && 'direction: rtl;'}

  li {
    position: relative;
    text-align: ${textAlign};

    &:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;

const ErrorScreen = ({ history }) => {
  const { t } = useTranslation();
  const { message: errorText } = useSelector(selectPaymentError);
  const POINTS = ['your_card_balance', 'limits_on_card', 'contact_card_issue'];
  const eventsData = useSelector(selectEventsData);
  const dispatch = useDispatch();

  const renderCheckPoints = (point) => <li key={point}>{t(point)}</li>;

  const tryAgainClick = () => {
    dispatch(setPaymentError(false));
    dispatch(setFormValidity(null));
    sendAnalyticsEvents(EVENTS.onboardingDeclineTryAgain, eventsData);
    history.push('/checkout');
  };

  return (
    <ErrorWrapper>
      {ErrorIcon}
      <Title>{t('payment_declined')}</Title>
      <ErrorText>
        {errorText || 'The card was declined for an unknown reason.'}
      </ErrorText>
      <StyledList>{POINTS.map(renderCheckPoints)}</StyledList>
      <UIButton onClick={tryAgainClick} customId={'error-button'}>
        {t('try_again')}
      </UIButton>
    </ErrorWrapper>
  );
};
export default ErrorScreen;
