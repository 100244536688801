import { paypalIcon } from './Icons';
import styled from 'styled-components';

const Button = styled.div`
  width: 100%;
  max-width: 327px;
  height: 46px;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: #f9c456;
  border-radius: 24px;
  cursor: pointer;
`;

const PaypalButton = ({ id, onClick }) => (
  <Button id={id} onClick={onClick}>
    {paypalIcon}
  </Button>
);

export default PaypalButton;
