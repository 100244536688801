export const GOOGLE_TRACKING_ID = process.env.REACT_APP_GOOGLE_TRACKING_ID;

export const GOOGLE_ADS_ACCOUNT_ID = '9203700535-WEB';

export const getGoogleClientID = (cb) => {
  if (window.ga) {
    window.ga('create', GOOGLE_TRACKING_ID, 'auto');

    window.ga((tracker) => {
      cb(tracker.get('clientId'));
    });
  }
};
