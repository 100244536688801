import { createSlice } from '@reduxjs/toolkit';

export const optionsSlice = createSlice({
  name: 'options',
  initialState: {
    done: false,
    answeredCount: 0,
  },
  reducers: {
    setDone: (state, action) => {
      state.done = action.payload;
    },
    setAnsweredCount: (state, action) => {
      state.answeredCount += action.payload;

      if (state.answeredCount === 3) {
        state.done = true;
      }
    },
  },
});

export const { setAnsweredCount, setAnswers } = optionsSlice.actions;

export const selectDone = (state) => state.options.done;
export const selectAnsweredCount = (state) => state.options.answeredCount;

export default optionsSlice.reducer;
