import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectWebp } from '../ui/uiSlice';

const StyledList = styled.ul`
  display: flex;
  justify-content: space-between;
  width: 70%;
  margin: 0 auto auto;
  list-style: none;
  padding: 0;

  @media screen and (max-width: 374px) {
    width: 85%;
  }
`;

const CardIcons = () => {
  const isWebp = useSelector(selectWebp);
  return (
    <StyledList>
      <li>
        <img
          src={isWebp ? '../assets/visa.webp' : '../assets/visa.png'}
          alt="Visa"
          width="57"
          height="35"
        />
      </li>
      <li>
        <img
          src={
            isWebp ? '../assets/mastercard.webp' : '../assets/mastercard.png'
          }
          alt="Mastercard"
          width="57"
          height="35"
        />
      </li>
      <li>
        <img
          src={isWebp ? '../assets/amex.webp' : '../assets/amex.png'}
          alt="Amex"
          width="57"
          height="35"
        />
      </li>
      <li>
        <img
          src={isWebp ? '../assets/discover.webp' : '../assets/discover.png'}
          alt="Discover"
          width="57"
          height="35"
        />
      </li>
    </StyledList>
  );
};

export default CardIcons;
