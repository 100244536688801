import React from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { selectShowCheckout } from '../plans/plansSlice';
import CheckoutScreen from '../checkout/CheckoutScreen';
import PlansScreen from '../plans/PlansScreen';
import withStripeInit from '../../hoc/withStripeInit';

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const CheckoutContainer = styled.div`
  display: ${({ showCheckout }) => (showCheckout ? 'block' : 'none')};
  height: 100%;
`;

const PlanContainer = styled.div`
  display: ${({ showCheckout }) => (showCheckout ? 'none' : 'block')};
`;

const PlanCheckout = (props) => {
  const showCheckout = useSelector(selectShowCheckout);

  return (
    <Wrapper>
      <PlanContainer showCheckout={showCheckout}>
        <PlansScreen />
      </PlanContainer>
      <CheckoutContainer showCheckout={showCheckout}>
        <CheckoutScreen {...props} />
      </CheckoutContainer>
    </Wrapper>
  );
};

export default withStripeInit(PlanCheckout);
