import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { centeredBG, firstScreensContainer } from '../../mixins';
import ScreenContent from '../../UI/ScreenContent';
import ProgrBar from '../../UI/ProgrBar';
import { sendAnalyticsEvents } from '../../analytics/common';
import { EVENTS } from '../../analytics/events';
import { selectWebp } from '../ui/uiSlice';
import { selectEventsData } from '../events/eventsSlice';
import useBackgroundSrc from '../../helper/useBackgroundSrc';
import { appendLink } from '../../helper/utils';

const ScreenContainer = styled.div`
  background-image: ${(props) =>
    props.supportsWebP
      ? `url('../assets/desktop/screen-two-desk.webp')`
      : `url('../assets/desktop/screen-two-desk.png')`};
  ${firstScreensContainer};
  ${centeredBG};
  background-position: top;
  @media screen and (max-width: 430px) {
    background-image: ${(props) =>
      props.supportsWebP
        ? `url('../assets/mobile/screen-two.webp')`
        : `url('../assets/mobile/screen-two.png')`};
    background-position: center;
  }
`;

const ScreenTwo = (props) => {
  const { t } = useTranslation();
  const isWebp = useSelector(selectWebp);
  const eventsData = useSelector(selectEventsData);
  const { backgroundSources } = useBackgroundSrc();

  useEffect(() => {
    appendLink(`${backgroundSources.three}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(
    () => sendAnalyticsEvents(EVENTS.screen2Shown, eventsData),
    [eventsData]
  );

  return (
    <ScreenContainer supportsWebP={isWebp}>
      <ProgrBar now={33} />
      <ScreenContent
        title={t('check_location')}
        text={t('screen_2_text')}
        btn={t('continue')}
        onClick={() => props.history.push('/place-alerts')}
        longText
      />
    </ScreenContainer>
  );
};

export default ScreenTwo;
