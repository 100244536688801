import amplitude from 'amplitude-js';

const amplitudeEventsList = [
  'OnboardingPaywallShown',
  'OnboardingPaywallChosen',
  'OnboardingTryFreeShown',
  'OnboardingPaymentShown',
  'CardCheckoutChosen',
  'PaypalCheckoutChosen',
  'OnboardingSuccessShown',
  'PaymentDeclinedInsufFunds',
  'SubBuyTapped',
  'SubsPurchaseFailed',
  'PaywallClosed',
  'PaywallSubscribed',
];

export const initAmplitude = (userId, callback) => {
  amplitude
    .getInstance()
    .init(process.env.REACT_APP_AMPLITUDE_KEY, userId || null, null, () =>
      callback('amplitude')
    );
};

export const setAmplitudeUserId = (userId) => {
  amplitude.getInstance().setUserId(userId);
};

export const sendAmplitudeData = (eventType, eventParams) => {
  if (!amplitudeEventsList.includes(eventType)) return;
  const checkInit = setInterval(() => {
    if (window.sessionStorage.getItem('amplitudeInit') === 'true') {
      amplitude.getInstance().logEvent(eventType, eventParams);
      clearInterval(checkInit);
    }
  }, 500);
};

export const setAmplitudeUserProperties = (properties) => {
  const checkInit = setInterval(() => {
    if (window.sessionStorage.getItem('amplitudeInit') === 'true') {
      amplitude.getInstance().setUserProperties(properties);
      clearInterval(checkInit);
    }
  }, 500);
};

export const sendAmplitudeRevenue = (eventParams) => {
  const { price, productId } = eventParams;
  const revenue = new amplitude.Revenue()
    .setProductId(productId)
    .setPrice(price);

  amplitude.getInstance().logRevenueV2(revenue);
};
