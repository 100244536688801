import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { isBrowser } from 'react-device-detect';
import { selectToken, selectUserUuid } from '../signup/signupSlice';
import { setMetrics } from '../../analytics/eCommerce';
import { selectLandingType, selectPlan } from '../plans/plansSlice';
import UIButton from '../../UI/UIButton';
import { MediumTitle, fadeInAnimation, wh, screenPadding } from '../../mixins';
import { sendAnalyticsEvents } from '../../analytics/common';
import { EVENTS } from '../../analytics/events';
import {
  selectBraintreeNonce,
  selectID,
  selectStripeNonce,
  selectUpsellData,
} from '../checkout/checkoutSlice';
import SuccessLogo from '../../UI/SuccessLogo';
import { selectEventsData } from '../events/eventsSlice';

const APP_LINK = process.env.REACT_APP_WEB_APP_URL;

const StyledContainer = styled.div`
  ${wh('100%')};
  ${isBrowser && fadeInAnimation};
  ${screenPadding};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (min-width: 768px) {
    padding: 24px 6px 32px;
    height: calc(100vh - 116px);
    min-height: unset;
  }
`;

const Title = styled.h1`
  ${MediumTitle};
  margin: 32px 0;
`;

const SuccessScreen = () => {
  const { t } = useTranslation();
  const plan = useSelector(selectPlan);
  const orderID = useSelector(selectID);
  const userUuid = useSelector(selectUserUuid);
  const landingType = useSelector(selectLandingType);
  const token = useSelector(selectToken);
  const eventsData = useSelector(selectEventsData);
  const upsellData = useSelector(selectUpsellData);
  const stripeNonce = useSelector(selectStripeNonce);
  const braintreeNonce = useSelector(selectBraintreeNonce);
  const eventId = `${stripeNonce || braintreeNonce}_${userUuid}`;

  useEffect(
    () => sendAnalyticsEvents(EVENTS.successScreenShown, eventsData),
    [eventsData]
  );

  useEffect(() => {
    if (plan && orderID) {
      const gclid = eventsData?.gclid || '';
      setMetrics(
        plan,
        orderID,
        null,
        upsellData?.success,
        landingType,
        eventId,
        gclid
      );
    }
  }, [plan, orderID, upsellData, landingType, eventId, eventsData]);

  const eventsDataEncoded = btoa(
    unescape(encodeURIComponent(JSON.stringify(eventsData)))
  );

  const onStartClick = () => {
    const url = `${APP_LINK}onboarding-login?token=${token}&data=${eventsDataEncoded}&payment=true`;
    window.location.assign(url);
  };

  return (
    <StyledContainer>
      <SuccessLogo />
      <Title>{t('your_plans_awaits')}</Title>

      <UIButton onClick={onStartClick}>{t('start_tracking')}</UIButton>

      <input type={'hidden'} id={'end-of-onboarding'} />
    </StyledContainer>
  );
};

export default SuccessScreen;
