import { createSlice } from '@reduxjs/toolkit';
import { setError } from '../error/errorSlice';
import { setCheckoutLoader } from '../loader/loaderSlice';
import { sendAnalyticsEvents } from '../../analytics/common';
import { EVENTS } from '../../analytics/events';
import {
  getPollingID,
  getSubscriptionID,
  sendStripeReq,
  subscribeToPriceChange,
} from './fetch';
import { calcPercent } from '../../helper/utils';
import { setEventData, setEventDataList } from '../events/eventsSlice';
import { setAlternativeStripeDataAsMain } from '../signup/signupSlice';

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: {
    cardholderName: '',
    token: null,
    isSuccess: null,
    paymentError: {
      show: false,
      message: '',
    },
    isFormValid: null,
    stripeToken: null,
    id: null,
    finalPrice: null,
    regularPrice: null,
    isCardFormShown: false,
    discountPercentage: null,
    stripeNonce: '',
    braintreeNonce: '',
    upsellData: null,
  },
  reducers: {
    setCardholderName: (state, action) => {
      state.cardholderName = action.payload;
    },
    setRequestResult: (state, action) => {
      const { isSuccess, id, finalPrice, regularPrice } = action.payload;

      state.isSuccess = isSuccess;
      state.id = id;
      state.finalPrice = finalPrice;
      state.regularPrice = regularPrice;
    },
    setUpsellData: (state, action) => {
      state.upsellData = {
        success: action.payload?.isSuccess,
        finalPrice: action.payload?.finalPrice,
        regularPrice: action.payload?.regularPrice,
      };
    },
    setDiscountPercentage: (state, action) => {
      const { finalPrice, regularPrice } = action.payload;
      state.discountPercentage = calcPercent(
        regularPrice - finalPrice,
        regularPrice
      );
    },
    setFormValidity: (state, action) => {
      state.isFormValid = action.payload;
    },
    setStripeNonce: (state, action) => {
      state.stripeNonce = action.payload;
    },
    setBraintreeNonce: (state, action) => {
      state.braintreeNonce = action.payload;
    },
    setIfCardFormShown: (state, action) => {
      state.isCardFormShown = action.payload;
    },
    setPaymentError: (state, action) => {
      const show = action.payload;
      if (typeof show === 'string') {
        state.paymentError = {
          show: true,
          message: show,
        };
      } else {
        state.paymentError = {
          show: !!show,
          message: '',
        };
      }
    },
  },
});

export const {
  setCardholderName,
  setStripeNonce,
  setBraintreeNonce,
  setRequestResult,
  setDiscountPercentage,
  setFormValidity,
  setIfCardFormShown,
  setUpsellData,
  setPaymentError,
} = checkoutSlice.actions;

export const sendServerReq = async (
  stripe,
  paymentMethodObj,
  plan,
  token,
  analyticsParams,
  eventsData,
  onError,
  onSuccess,
  eventId,
  upsell,
  payment_intent_id
) => {
  const { stripeNonce, methodName } = paymentMethodObj;
  const pollingID = await getPollingID(
    stripeNonce,
    plan,
    token,
    analyticsParams,
    eventsData,
    onError,
    eventId,
    upsell,
    payment_intent_id
  );
  const purchaseRequest = await subscribeToPriceChange(pollingID);
  const { status, errors, data } = purchaseRequest || {
    errors: { messages: 'Unknown error. Please try again' },
  };

  if (status) {
    if (status === 'requires_action' && data && data.payment_intent_secret) {
      const cardPaymentSecret = data.payment_intent_secret;
      const cardPaymentData = methodName
        ? { payment_method: stripeNonce }
        : { setup_future_usage: 'off_session' };
      const cardPaymentOptions = methodName ? { handleActions: false } : {};

      const { error: errorAction, paymentIntent } =
        await stripe.confirmCardPayment(
          cardPaymentSecret,
          cardPaymentData,
          cardPaymentOptions
        );

      if (errorAction) {
        if (methodName) {
          onError(errorAction.message, data);
        } else {
          await sendServerReq(
            stripe,
            paymentMethodObj,
            plan,
            token,
            analyticsParams,
            eventsData,
            onError,
            onSuccess,
            eventId,
            upsell,
            data.payment_intent_id
          );
        }
        return;
      }
      if (methodName) {
        if (paymentIntent.status === 'requires_action') {
          const { error } = await stripe.confirmCardPayment(
            data.payment_intent_secret
          );
          if (error) {
            onError(error, data);
            return;
          }
        }

        onSuccess();
      }

      await sendServerReq(
        stripe,
        paymentMethodObj,
        plan,
        token,
        analyticsParams,
        eventsData,
        onError,
        onSuccess,
        eventId,
        upsell,
        paymentIntent.id
      );
    } else {
      const { subscription_id, price_with_discount, regular_price } =
        purchaseRequest;

      return onSuccess(subscription_id, price_with_discount, regular_price);
    }
  }

  if (errors) {
    if (status === 'requires_action') return;

    if (errors.messages.includes('insufficient')) {
      sendAnalyticsEvents(EVENTS.paymentDeclined, eventsData);
    }

    onError(errors.messages || 'An error has occurred', data);
  }
};

export const sendMainPurchaseRequest =
  (
    paymentMethodObj = null,
    stripe,
    card,
    name,
    plan,
    token,
    analyticsParams,
    eventsData,
    eventId,
    successPayment,
    failedPayment
  ) =>
  async (dispatch) => {
    const onError = (data, errorData) => {
      if (failedPayment) failedPayment();

      let txt = data;
      if (typeof txt !== 'string') txt = data.message;
      if (typeof txt !== 'string') {
        txt = 'Payment error';
        console.log(data);
      }

      dispatch(
        setEventData({
          id: 'decline_code',
          data: errorData?.decline_code || errorData?.processor_response,
        })
      );
      sendAnalyticsEvents(EVENTS.subBuyFailed, {
        ...eventsData,
        declineCode: errorData?.decline_code || errorData?.processor_response,
      });
      dispatch(setPaymentError(txt));
      dispatch(setAlternativeStripeDataAsMain());
    };
    const onSuccess = (id, finalPrice, regularPrice) => {
      if (successPayment) successPayment();

      if (finalPrice) {
        dispatch(setDiscountPercentage({ finalPrice, regularPrice }));
      }
      sendAnalyticsEvents(EVENTS.subscribed, eventsData);
      dispatch(
        setRequestResult({ isSuccess: true, id, finalPrice, regularPrice })
      );
    };

    dispatch(setCheckoutLoader({ show: true, type: 'checkout' }));

    try {
      if (paymentMethodObj) {
        dispatch(setStripeNonce(paymentMethodObj.stripeNonce));
        await sendServerReq(
          stripe,
          paymentMethodObj,
          plan,
          token,
          analyticsParams,
          eventsData,
          onError,
          onSuccess,
          eventId,
          '',
          ''
        );
      } else {
        const { paymentMethod, error } = await sendStripeReq(
          stripe,
          card,
          name
        );
        const stripeNonce = paymentMethod?.id || error?.code;

        if (error) {
          console.error('Failed to make a payment: ', error.message);
          dispatch(
            setError({ show: true, text: error.message, type: 'payment' })
          );
        } else {
          dispatch(setStripeNonce(stripeNonce));
          await sendServerReq(
            stripe,
            { stripeNonce },
            plan,
            token,
            analyticsParams,
            eventsData,
            onError,
            onSuccess,
            eventId,
            '',
            ''
          );
        }
      }
    } finally {
      dispatch(setCheckoutLoader({ show: false, type: '' }));
    }
  };

export const braintreePurchase =
  (
    braintreeNonce,
    binData,
    threeDSInstance,
    plan,
    token,
    analyticsParams,
    eventsData,
    userUuid,
    coupon
  ) =>
  async (dispatch) => {
    dispatch(setBraintreeNonce(braintreeNonce));

    const onError = (data, errorData) => {
      let txt = data;
      if (typeof txt !== 'string') txt = data.message;
      if (typeof txt !== 'string') {
        txt = 'Payment error';
        console.log(data);
      }

      dispatch(
        setEventData({
          id: 'decline_code',
          data: errorData?.decline_code || errorData?.processor_response,
        })
      );
      sendAnalyticsEvents(EVENTS.subBuyFailed, {
        ...eventsData,
        declineCode: errorData?.decline_code || errorData?.processor_response,
      });
      dispatch(setError({ show: true, text: txt, type: 'payment' }));
      dispatch(setCheckoutLoader({ show: false, type: '' }));
    };
    const onSuccess = ({ customer_id, subscription_id }) => {
      const localData = {
        customer_id,
        subscription_id,
        plan_id: plan,
      };
      dispatch(setRequestResult({ isSuccess: true, id: subscription_id }));
      dispatch(setEventDataList(localData));
      sendAnalyticsEvents(EVENTS.subscribed, { ...eventsData, ...localData });
      dispatch(setCheckoutLoader({ show: false, type: '' }));
    };

    const purchaseRequest = await getSubscriptionID(
      braintreeNonce,
      plan,
      token,
      analyticsParams,
      eventsData,
      userUuid,
      ''
    );

    try {
      const { errors, data } = purchaseRequest;
      if (errors) {
        if (errors?.messages === 'User action required') {
          const { payment_method_nonce, validation_amount } = data;
          dispatch(setBraintreeNonce(payment_method_nonce));

          threeDSInstance.verifyCard(
            {
              nonce: payment_method_nonce,
              bin: binData,
              amount: validation_amount,
            },
            (verifyError, payload) => {
              if (verifyError) {
                console.error('verify error: ', verifyError);
                onError(verifyError, data);
                return;
              }
              // console.log('verify: ', payload);

              dispatch(
                braintreePurchase(
                  payload.nonce,
                  binData,
                  threeDSInstance,
                  plan,
                  token,
                  analyticsParams,
                  eventsData,
                  userUuid
                )
              );
            }
          );
          return;
        }

        onError(errors?.messages || 'An error has occurred', data);
        return;
      }
      onSuccess(data);
    } catch (err) {
      onError(err);
    } finally {
      dispatch(setCheckoutLoader({ show: false, type: '' }));
    }
  };

export const selectSuccess = (state) => state.checkout.isSuccess;
export const selectPaymentError = (state) => state.checkout.paymentError;
export const selectName = (state) => state.checkout.cardholderName;
export const selectFormValidity = (state) => state.checkout.isFormValid;
export const selectID = (state) => state.checkout.id;
export const selectStripeNonce = (state) => state.checkout.stripeNonce;
export const selectBraintreeNonce = (state) => state.checkout.braintreeNonce;
export const selectFinalPrice = (state) => state.checkout.finalPrice;
export const selectUpsellData = (state) => state.checkout.upsellData;
export const selectDiscountAmount = (state) =>
  state.checkout.discountPercentage;
export const selectIfCardFormShown = (state) => state.checkout.isCardFormShown;

export default checkoutSlice.reducer;
