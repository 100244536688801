import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  osName,
  osVersion,
  isDesktop,
  isIOS,
  isAndroid,
} from 'react-device-detect';
import { centeredBG, firstScreensContainer, fontSize } from '../../mixins';
import ScreenContent from '../../UI/ScreenContent';
import { selectWebp, selectLoad } from '../ui/uiSlice';
import { sendAnalyticsEvents } from '../../analytics/common';
import { EVENTS } from '../../analytics/events';
import { setLandingType } from '../plans/plansSlice';
import { setEventDataList } from '../events/eventsSlice';
import { useLandingType, queryParser } from 'onboarding-initialization';
import landingTypesList from '../../helper/landingTypes';
import { setAmplitudeUserProperties } from '../../analytics/amplitude';
import ProgrBar from '../../UI/ProgrBar';

const WelcomeBlock = styled.div`
  background-image: ${(props) =>
    props.supportsWebP
      ? `url('../assets/desktop/screen-one-desk.webp')`
      : `url('../assets/desktop/screen-one-desk.png')`};
  ${firstScreensContainer}
  ${centeredBG};
  background-position: top;
  @media screen and (max-width: 430px) {
    background-image: ${(props) =>
      props.supportsWebP
        ? `url('../assets/mobile/screen-one.webp')`
        : `url('../assets/mobile/screen-one.png')`};
    background-position: center;
  }
`;

const StyledBtnWelcomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: absolute;
  bottom: 8px;
`;

const StyledDisclaimer = styled.div`
  ${fontSize(12)};
  color: rgba(255, 255, 255, 0.5);
  text-align: center;
  padding: 5px 0;

  span {
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Welcome = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [eventParams, setEventParams] = useState(null);
  const isWebp = useSelector(selectWebp);
  const load = useSelector(selectLoad);
  const {
    location: { pathname },
  } = props;
  const landingParam = pathname.replace(/\//g, '');
  const { landingType, paywallType } = useLandingType(
    landingParam,
    landingTypesList
  );

  useEffect(() => {
    if (eventParams || !paywallType) return;
    const {
      history: {
        location: { search },
      },
    } = props;
    const { fbclid, gclid, ...utms } = queryParser(search);
    const {
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      AdSetName,
      AdName,
      CampaignID,
      AdSetID,
      AdID,
    } = utms;
    const facebookUtm = fbclid ? 'facebook' : null;
    const appDomain = `${window.location.origin}${
      landingParam.length ? `/${landingParam}` : ''
    }`;
    const utmSource = utms.utm_source || facebookUtm || 'organic';
    utms.utm_source = utmSource;

    dispatch(setLandingType(paywallType));

    const localEventsData = {
      landing_type: landingType,
      app_domain: appDomain,
      appDomain: appDomain,
      app_name: 'GeoZilla',
      fbclid,
      gclid,
      utm: utms,
      utm_source: utmSource,
      os: `${osVersion} ${osName}`,
    };

    const userProperties = {
      AppDomain: appDomain,
      OSDevice: `${
        isDesktop ? 'desktop' : isIOS ? 'iOS' : isAndroid ? 'Android' : osName
      }`,
      utm_source,
      utm_medium,
      utm_campaign,
      utm_content,
      AdSetName,
      AdName,
      CampaignID,
      AdSetID,
      AdID,
    };

    dispatch(setEventDataList(localEventsData));
    setAmplitudeUserProperties(userProperties);
    setEventParams(localEventsData);
  }, [props, dispatch, eventParams, landingParam, landingType, paywallType]);

  useEffect(
    () =>
      eventParams &&
      setTimeout(
        () => sendAnalyticsEvents(EVENTS.onboardingStarted, eventParams),
        1000
      ),
    [eventParams]
  );

  const handleBtnClick = () => {
    props.history.push('/check-location');
  };

  const onLinkClick = (url) => window.open(url);

  useEffect(() => {
    const originalStyles = {
      body: { ...document.body.style },
      html: { ...document.documentElement.style },
    };

    document.body.style.minHeight = 'unset';
    document.body.style.height = '100%';
    document.documentElement.style.minHeight = 'unset';
    document.documentElement.style.height = '100%';

    return () => {
      document.body.style = originalStyles.body;
      document.documentElement.style = originalStyles.html;
    };
  }, []);

  if (load) {
    return (
      <WelcomeBlock supportsWebP={isWebp}>
        <ProgrBar now={0} />
        <ScreenContent
          title={t('welcome')}
          text={t('screen_1_text')}
          btn={t('continue')}
          onClick={handleBtnClick}
        />
        <StyledBtnWelcomeWrapper>
          <StyledDisclaimer
            dangerouslySetInnerHTML={{ __html: t('welcome_disclaimer') }}
            onClick={(e) => {
              if (e.target.id === 'terms') {
                onLinkClick('https://geozilla.com/terms-of-use/');
              }
            }}
          />
        </StyledBtnWelcomeWrapper>
      </WelcomeBlock>
    );
  }

  return <></>;
};

export default Welcome;
