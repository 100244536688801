import CircularProgress from '@material-ui/core/CircularProgress';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { isMobile } from 'react-device-detect';
import { fontSize } from '../mixins';

const isStage = process.env.REACT_APP_ENV === 'stage';
const isArabic = navigator.language.startsWith('ar');
const textAlign = isArabic ? 'right' : 'left';

const StyledPercentage = styled.p`
  font-weight: 700;
  ${fontSize(46)};
  line-height: 55px;
  color: ${(props) => (props.theme === 'dark' ? '#fff' : '#323232')};
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;

  @media screen and (max-width: 767px) {
    ${fontSize(28)};
    line-height: 32px;
  }
`;

const StyledList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  flex-wrap: wrap;
  max-width: 750px;

  @media screen and (min-width: 767px) {
    display: flex;
    flex-direction: ${isArabic ? 'row-reverse' : 'row'};
    justify-content: space-evenly;
  }

  li {
    min-width: 360px;
    color: ${(props) => (props.theme === 'dark' ? '#fff' : '#323232')};
    ${fontSize(17)};
    line-height: 20px;
    font-weight: 400;
    display: flex;
    flex-direction: ${isArabic ? 'row-reverse' : 'row'};
    justify-content: flex-start;
    align-items: center;
  }
`;

const StyledStatus = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: ${(props) => (props.isActive ? '#00BFA5' : 'transparent')};
  border-style: solid;
  border-width: 2px;
  border-color: ${(props) => (props.isActive ? '#00BFA5' : '#E0E0E0')};
  position: relative;
  color: #fff;
  margin-right: 12px;
  margin-left: 12px;
  margin-${textAlign}: 0;
  transition: all 0.1s linear;
  transition-property: background-color, border-color;
`;

const CreatingProfileUI = ({ doneCallback, theme }) => {
  const circleSize = isMobile ? 180 : 280;
  const [progress, setProgress] = useState(0);
  const { t } = useTranslation();

  const useCircleProgress = makeStyles(() => ({
    root: {
      borderRadius: 5,
      position: 'relative',
      margin: '40px auto',
    },
    circle: {
      color: '#00BFA5',
      strokeLinecap: 'round',
      position: 'absolute',
      left: 0,
    },
    bottom: {
      color: '#E0E0E0',
      left: 0,
    },
  }));

  const classes = useCircleProgress();

  useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) =>
        prevProgress >= 100 ? 100 : prevProgress + 1
      );
    }, 100);
    return () => {
      clearInterval(timer);
    };
  }, []);

  useEffect(() => {
    if (progress === 100) {
      doneCallback();
    }
  }, [progress, doneCallback]);

  const onPercentClick = () => {
    isStage && doneCallback();
  };

  return (
    <>
      <div className={classes.root}>
        <CircularProgress
          value={100}
          variant="determinate"
          className={classes.bottom}
          thickness={2}
          size={circleSize}
        />
        <StyledPercentage theme={theme}>{`${progress}%`}</StyledPercentage>
        <CircularProgress
          variant="determinate"
          value={progress}
          className={classes.circle}
          thickness={2}
          size={circleSize}
        />
      </div>

      <StyledList theme={theme}>
        <li onClick={onPercentClick} id={'test-creating-profile-skip'}>
          <StyledStatus isActive={progress >= 25}>
            {progress >= 25 && '✔'}
          </StyledStatus>
          <p>{t('checking_current_location')}</p>
        </li>
        <li>
          <StyledStatus isActive={progress >= 50}>
            {progress >= 50 && '✔'}
          </StyledStatus>
          <p>{t('setting_up_crash_detection')}</p>
        </li>
        <li>
          <StyledStatus isActive={progress >= 75}>
            {progress >= 75 && '✔'}
          </StyledStatus>
          <p>{t('adding_place_alerts')}</p>
        </li>
        <li>
          <StyledStatus isActive={progress >= 95}>
            {progress >= 95 && '✔'}
          </StyledStatus>
          <p>{t('updating_location_history')}</p>
        </li>
      </StyledList>
    </>
  );
};

export default CreatingProfileUI;
