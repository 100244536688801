import supportsWebP from 'supports-webp';
import * as Sentry from '@sentry/browser';

export const fetchWrapper = (url, { body, ...customConfig } = {}) => {
  const headers = { 'Content-Type': 'application/json' };

  const config = {
    method: body ? 'POST' : 'GET',
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers,
    },
  };

  if (body) {
    config.body = JSON.stringify(body);
  }

  return window.fetch(url, config).then(async (response) => {
    const data = await response.json();
    if (response.ok) {
      return data;
    }
    return Promise.reject(data);
  });
};

export const getCookie = (name) => {
  // eslint-disable-next-line
    const regex = new RegExp('(?:^|; )' + name.replace(/([$?*|{}\[\]\\\/^])/g, '\\$1') + '=([^;]*)');
  const matches = document.cookie.match(regex);
  return matches ? decodeURIComponent(matches[1]) : undefined;
};

export const calcPercent = (partialValue, totalValue) =>
  (100 * partialValue) / totalValue;

export const queryParser = (str) => {
  const cutStr = str.slice(1);
  const coupleStr = cutStr.split('&').map((item) => item.split('='));
  const obj = coupleStr.reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key.replace('+', '_')]: decodeURIComponent(value),
    }),
    {}
  );
  return obj;
};

export const plansImagesLinks = [
  './assets/plans-slider/image1.png',
  './assets/plans-slider/image2.png',
  './assets/plans-slider/image3.png',
  './assets/plans-slider/image4.png',
];

export const plansWebpImagesLinks = [
  './assets/plans-slider/image1.webp',
  './assets/plans-slider/image2.webp',
  './assets/plans-slider/image3.webp',
  './assets/plans-slider/image4.webp',
];

export const checkoutImagesLinks = [
  './assets/visa.png',
  './assets/mastercard.png',
  './assets/amex.png',
  './assets/discover.png',
];

export const checkoutWebpImagesLinks = [
  './assets/visa.webp',
  './assets/mastercard.webp',
  './assets/amex.webp',
  './assets/discover.webp',
];

export const appendLink = (href) => {
  const link = document.createElement('link');
  link.setAttribute('rel', 'prefetch');
  link.setAttribute('href', href);
  link.setAttribute('as', 'image');
  document.head.appendChild(link);
};

export const appendLinkOnLoad = (href, type = 'prefetch') =>
  new Promise((resolve, reject) => {
    const link = document.createElement('link');
    link.setAttribute('rel', type);
    link.setAttribute('href', href);
    link.setAttribute('as', 'image');
    link.onload = resolve();
    document.head.appendChild(link);
  });

export const canUseWebp = async () => await supportsWebP;

export const componentLoader = (
  lazyComponent,
  attemptsLeft = 3,
  interval = 1000
) =>
  new Promise((resolve, reject) => {
    lazyComponent()
      .then(resolve)
      .catch((error) => {
        setTimeout(() => {
          if (attemptsLeft === 1) {
            const connection = navigator.connection ||
              navigator.mozConnection ||
              navigator.webkitConnection || {
                effectiveType: 'no connection data',
              };
            Sentry.setContext('NetworkInformation', {
              effectiveType: connection.effectiveType,
            });
            reject(error);
            return;
          }
          componentLoader(lazyComponent, attemptsLeft - 1, interval).then(
            resolve,
            reject
          );
        }, interval);
      });
  });
