import React from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { fadeInDelay } from '../../mixins';
import {
  selectBtToken,
  selectStripeAccountName,
  selectStripePublishKey,
  selectStripeAccountName2,
  selectStripePublishKey2,
  selectToken,
  selectAvailablePayments,
} from '../signup/signupSlice';
import { selectLandingType } from './plansSlice';
import { selectIfCardFormShown } from '../checkout/checkoutSlice';
import { selectEventsData, selectAnalyticsData } from '../events/eventsSlice';

const APP_LINK = process.env.REACT_APP_WEB_APP_URL;

const StyledCross = styled.button`
  position: absolute;
  width: 40px;
  height: 40px;
  padding: 6px;
  top: 12px;
  right: 2%;
  ${fadeInDelay};
  border: none;
  background-color: transparent;
  opacity: ${(props) => (props.cardFormVisited ? '1' : '0')};

  svg {
    width: 12px;
    height: 12px;
  }

  &:focus {
    outline: none;
  }
`;

const CrossBtn = () => {
  const token = useSelector(selectToken);
  const btToken = useSelector(selectBtToken);
  const stripeAccountName = useSelector(selectStripeAccountName);
  const stripePublishKey = useSelector(selectStripePublishKey);
  const stripeAccountName2 = useSelector(selectStripeAccountName2);
  const stripePublishKey2 = useSelector(selectStripePublishKey2);
  const landingType = useSelector(selectLandingType);
  const cardFormVisited = useSelector(selectIfCardFormShown);
  const amplitudeEventsData = useSelector(selectEventsData);
  const facebookPurchaseParams = useSelector(selectAnalyticsData);
  const availablePayments = useSelector(selectAvailablePayments);

  const stripeSettings = {
    stripePublishKey,
    stripeAccountName,
    availablePayments,
    alternativeSettings: {
      stripePublishKey2,
      stripeAccountName2,
    },
  };
  const eventsDataEncoded = btoa(
    unescape(
      encodeURIComponent(
        JSON.stringify({
          ...amplitudeEventsData,
          ...facebookPurchaseParams,
          stripeSettings,
        })
      )
    )
  );

  const onStartClick = () => {
    const url = `${APP_LINK}onboarding-login?token=${token}&btToken=${btToken}&data=${eventsDataEncoded}&offerType=${landingType}`;
    window.location.assign(url);
  };

  return (
    <StyledCross
      cardFormVisited={cardFormVisited}
      onClick={onStartClick}
      id={'paywall-skip-button'}
    >
      <svg
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.41413 6.00001L11.7071 1.70701C12.0981 1.31601 12.0981 0.684006 11.7071 0.293006C11.3161 -0.0979941 10.6841 -0.0979941 10.2931 0.293006L6.00013 4.58601L1.70713 0.293006C1.31613 -0.0979941 0.684128 -0.0979941 0.293128 0.293006C-0.0978721 0.684006 -0.0978721 1.31601 0.293128 1.70701L4.58613 6.00001L0.293128 10.293C-0.0978721 10.684 -0.0978721 11.316 0.293128 11.707C0.488128 11.902 0.744128 12 1.00013 12C1.25613 12 1.51213 11.902 1.70713 11.707L6.00013 7.41401L10.2931 11.707C10.4881 11.902 10.7441 12 11.0001 12C11.2561 12 11.5121 11.902 11.7071 11.707C12.0981 11.316 12.0981 10.684 11.7071 10.293L7.41413 6.00001Z"
          fill="white"
        />
      </svg>
    </StyledCross>
  );
};

export default CrossBtn;
