import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { isBrowser } from 'react-device-detect';
import {
  centeredFlex,
  fadeInAnimation,
  fontSize,
  screenPadding,
} from '../../mixins';
import { selectIfCheckoutVisited } from '../plans/plansSlice';
import { sendAnalyticsEvents } from '../../analytics/common';
import { EVENTS } from '../../analytics/events';
import { selectPaymentSettingsStatus } from '../signup/signupSlice';
import CreatingProfileUI from '../../UI/CreatingProfileUI';
import { selectEventsData } from '../events/eventsSlice';

const StyledTitle = styled.h1`
  font-weight: 500;
  ${fontSize(24)};
  line-height: 40px;
  color: ${(props) => (props.theme === 'dark' ? '#fff' : '#323232')};
`;

const StyledContainer = styled.div`
  ${centeredFlex};
  ${screenPadding};
  ${isBrowser && fadeInAnimation};
  max-width: 900px;
`;

const CreatingProfile = ({ history }) => {
  const eventsData = useSelector(selectEventsData);
  const isCheckoutVisited = useSelector(selectIfCheckoutVisited);
  const { t } = useTranslation();
  const settingsStatus = useSelector(selectPaymentSettingsStatus);

  useEffect(
    () => sendAnalyticsEvents(EVENTS.creatingProfileAnim, eventsData),
    [eventsData]
  );

  useEffect(() => {
    if (isCheckoutVisited) {
      history.push('/checkout');
    }
  }, [isCheckoutVisited, history]);

  const doneCb = () => {
    if (settingsStatus) {
      history.push('/checkout');
    }
  };

  return (
    <StyledContainer>
      <StyledTitle theme={'dark'}>{t('creating_your_profile')}</StyledTitle>
      <CreatingProfileUI doneCallback={doneCb} theme={'dark'} />
    </StyledContainer>
  );
};

export default CreatingProfile;
