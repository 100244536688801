import React, { useEffect, useRef, useState, useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';
import { useSelector, useDispatch } from 'react-redux';
import { PaymentRequestButtonElement, Elements } from '@stripe/react-stripe-js';
import braintree from 'braintree-web';
import Stripe from './Stripe';
import UIButton from '../../UI/UIButton';
import {
  MediumTitle,
  screenPadding,
  centeredFlex,
  fontSize,
  wh,
} from '../../mixins';
import {
  braintreePurchase,
  sendMainPurchaseRequest,
  selectName,
  selectFormValidity,
  selectSuccess,
  selectPaymentError,
  selectIfCardFormShown,
  setIfCardFormShown,
  setCardholderName,
} from './checkoutSlice';
import {
  selectToken,
  selectBtToken,
  selectStripeAccountName,
  selectUserUuid,
  selectAvailablePayments,
  selectPaymentProvider,
} from '../signup/signupSlice';
import {
  selectDefaultPlanId,
  selectLandingType,
  selectPlan,
  selectShowCheckout,
  setPlan,
  setShowCheckout,
} from '../plans/plansSlice';
import BackButton from '../../UI/BackButton';
import { sendAnalyticsEvents } from '../../analytics/common';
import { EVENTS } from '../../analytics/events';
import CardIcons from './CardIcons';
import {
  selectEventsData,
  selectAnalyticsData,
  setEventDataList,
} from '../events/eventsSlice';
import { setError } from '../error/errorSlice';
import { setCheckoutLoader } from '../loader/loaderSlice';
import { PLANS } from '../../analytics/eCommerce';
import { setAmplitudeUserProperties } from '../../analytics/amplitude';
import Loader from '../../UI/Loader';
import Braintree from './Braintree';
import PaypalButton from './components/PaypalButton';

const isStage = process.env.REACT_APP_ENV === 'stage';
const textAlign = navigator.language.startsWith('ar') ? 'right' : 'left';
const enablePaypal = process.env.REACT_APP_ENABLE_PAYPAL === 'true';

const StyledContainer = styled.div`
  ${centeredFlex};
  ${screenPadding};
  min-height: unset;
  height: unset;
`;

const Title = styled.h1`
  ${MediumTitle}
`;

const PayWith = styled.div`
  margin-top: 24px;
  font-weight: 500;
  ${fontSize(16)};
  line-height: 22px;
  text-align: center;
  color: #ffffff;
`;

const PaymentRequestContainer = styled.div`
  width: 100%;
  max-width: 327px;
  div iframe {
    border-radius: 24px;
  }
`;

const StyledTerms = styled.p`
  font-weight: 400;
  ${fontSize(12)};
  line-height: 16px;
  text-align: center;
  color: #ffffff;
`;

const spinning = keyframes`
    to {
        transform: rotate(360deg);
       }
`;

const StyledCard = styled.div`
  width: 100%;
  min-height: 225px;
  margin: 40px auto 24px;
  position: relative;

  &:empty {
    &:after {
      content: '';
      display: block;
      box-sizing: border-box;
      position: absolute;
      top: 50%;
      left: 50%;
      ${wh('80px', '75px')};
      margin-top: -38px;
      margin-left: -40px;
      animation: ${spinning} 1.5s linear infinite;
      background-image: url(${'../assets/spinner-image.svg'});
    }
  }
`;

const AlternativePaymentButtons = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 16px;
`;

const style = {
  base: {
    color: '#000',
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: 300,
    fontFamily: 'Open Sans, Segoe UI, sans-serif',
    textAlign,

    '::placeholder': {
      color: '#ADADAD',
      letterSpacing: '1.2px',
      fontSize: '18px',
      lineHeight: '24px',
    },
  },
};

const CheckoutScreen = ({ history, stripePromise }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const name = useSelector(selectName);
  const plan = useSelector(selectPlan);
  const token = useSelector(selectToken);
  const isFormValid = useSelector(selectFormValidity);
  const isSuccess = useSelector(selectSuccess);
  const { show: showError } = useSelector(selectPaymentError);
  const [load, setLoad] = useState(false);
  const [paymentRequest, setPaymentRequest] = useState(null);
  const eventsData = useSelector(selectEventsData);
  const stripeInstance = useRef();
  const card = useRef();
  const expiration = useRef();
  const cvc = useRef();
  const cardholder = useRef(null);
  const braintreeInstance = useRef();
  const braintreeCardInstance = useRef();
  const braintreeThreeDSInstance = useRef();
  const analyticsParams = useSelector(selectAnalyticsData);
  const paypal = useRef();
  const btToken = useSelector(selectBtToken);
  const [planDetails, setPlanDetails] = useState(null);
  const stripeAccountName = useSelector(selectStripeAccountName);
  const userUuid = useSelector(selectUserUuid);
  const showCheckout = useSelector(selectShowCheckout);
  const defaultPlanId = useSelector(selectDefaultPlanId);
  const cardFormVisited = useSelector(selectIfCardFormShown);
  const availablePayments = useSelector(selectAvailablePayments);
  const landingType = useSelector(selectLandingType);
  const paymentProvider = useSelector(selectPaymentProvider);
  const isBraintree = paymentProvider === 'braintree';

  useEffect(() => {
    if (plan) {
      setPlanDetails(PLANS[plan]);
    }
  }, [plan, dispatch]);

  useEffect(() => {
    if (!isBraintree && stripePromise) {
      stripePromise.then((stripeObj) => {
        stripeInstance.current = stripeObj;
        const elements = stripeObj.elements();

        card.current = elements.create('cardNumber', {
          style: style,
          placeholder: '0000  0000  0000  0000',
        });
        expiration.current = elements.create('cardExpiry', {
          style: style,
          placeholder: t('MM/YY'),
        });
        cvc.current = elements.create('cardCvc', {
          style: style,
          placeholder: t('CVV'),
        });

        setLoad(true);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBraintree, stripePromise]);

  useLayoutEffect(() => {
    if (!isBraintree && stripeInstance.current && planDetails) {
      setPaymentRequest(null);
      const pr = stripeInstance.current.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: `GeoZilla ${planDetails?.description} plan`,
          amount: Math.round(planDetails?.periodPrice * 100),
        },
        displayItems: [
          {
            label: `GeoZilla ${planDetails?.description} plan`,
            amount: Math.round(planDetails?.periodPrice * 100),
          },
        ],
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then((result) => {
        if (result && cardFormVisited && eventsData) {
          setPaymentRequest(pr);
        }
      });

      pr.on('paymentmethod', async (e) => {
        const successPayment = () => e.complete('success');
        const failedPayment = () => e.complete('fail');

        stripePurchaseClick(e, successPayment, failedPayment);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    stripeInstance.current,
    planDetails,
    cardFormVisited,
    isBraintree,
    eventsData,
  ]);

  const initializeBraintree = (token) => {
    braintree.client.create(
      { authorization: token },
      (clientErr, clientInstance) => {
        if (clientErr) {
          console.error('Error creating client:', clientErr);
          return;
        }

        braintreeInstance.current = clientInstance;

        if (
          enablePaypal &&
          availablePayments.braintree.includes('paypal') &&
          landingType === 'fullPrice'
        ) {
          braintree.paypal.create(
            { client: clientInstance },
            (paypalErr, paypalInstance) => {
              if (paypalErr) {
                console.error('Error creating PayPal:', paypalErr);
                return;
              }

              paypal.current = paypalInstance;
            }
          );
        }
      }
    );
  };

  useEffect(() => {
    if (btToken && (isBraintree || enablePaypal)) {
      initializeBraintree(btToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [btToken, isBraintree]);

  const onPayPalClick = () => {
    const localEventsData = { payment_method: 'Paypal' };
    const extendedEventsData = {
      ...eventsData,
      ...localEventsData,
    };
    sendAnalyticsEvents(EVENTS.subBuyTapped, extendedEventsData);
    dispatch(setCheckoutLoader({ show: true, type: 'checkout' }));
    sendAnalyticsEvents(EVENTS.paymentAnimationScreen, extendedEventsData);

    paypal.current.tokenize(
      {
        flow: 'vault',
        currency: 'USD',
      },
      (tokenizeErr, payload) => {
        if (tokenizeErr) {
          if (tokenizeErr.type !== 'CUSTOMER') {
            console.error('Error tokenizing:', tokenizeErr);
          }
          dispatch(setCheckoutLoader({ show: false, type: '' }));
          return;
        }

        setAmplitudeUserProperties({ 'Payments gateways': 'Braintree' });
        dispatch(setEventDataList({ payment_method: 'Paypal' }));
        sendAnalyticsEvents(EVENTS.subBuyTapped, extendedEventsData);
        dispatch(
          braintreePurchase(
            payload.nonce,
            null,
            null,
            plan,
            token,
            analyticsParams,
            extendedEventsData,
            userUuid
          )
        );
      }
    );
  };

  const onPurchaseClick = () => {
    if (isBraintree) {
      braintreePurchaseClick();
    } else {
      stripePurchaseClick(null);
    }
  };

  const stripePurchaseClick = (
    paymentMethod = null,
    successPayment = null,
    failedPayment = null
  ) => {
    const paymentMethodName = paymentMethod?.walletName || 'Stripe';
    const paymentCardBrandName =
      paymentMethod?.card?.brand || eventsData.cardBrand || 'unknown';
    const paymentMethodObj = paymentMethod
      ? {
          methodName: paymentMethodName,
          stripeNonce: paymentMethod.paymentMethod.id,
        }
      : null;
    const localEventsData = {
      payment_method: paymentMethodName,
      cardBrand: paymentCardBrandName,
      card_brand: paymentCardBrandName,
      stripe_account_name: stripeAccountName,
    };
    const extendedEventsData = {
      ...eventsData,
      ...localEventsData,
    };
    setAmplitudeUserProperties({
      'Payments gateways': `Stripe [${stripeAccountName}]`,
    });
    dispatch(
      setEventDataList({ 'Payments gateways': `Stripe [${stripeAccountName}]` })
    );
    dispatch(setEventDataList(localEventsData));
    sendAnalyticsEvents(EVENTS.subBuyTapped, extendedEventsData);
    dispatch(
      sendMainPurchaseRequest(
        paymentMethodObj,
        stripeInstance.current,
        card.current,
        name,
        plan,
        token,
        analyticsParams,
        extendedEventsData,
        userUuid,
        successPayment,
        failedPayment
      )
    );
  };

  const braintreePurchaseClick = () => {
    const cardNumber = card.current.value;
    const cardExpiration = expiration.current.value;
    const cardCVV = cvc.current.value;

    const onError = (error) => {
      dispatch(setCheckoutLoader({ show: false, type: '' }));
      dispatch(setError({ show: true, text: error.message, type: 'payment' }));
    };

    const fullEventsData = {
      ...eventsData,
      payment_method: 'Braintree',
    };
    sendAnalyticsEvents(EVENTS.subBuyTapped, fullEventsData);
    dispatch(setCheckoutLoader({ show: true, type: 'checkout' }));
    sendAnalyticsEvents(EVENTS.paymentAnimationScreen, fullEventsData);

    braintreeCardInstance.current.tokenize(
      {
        number: cardNumber,
        expirationDate: cardExpiration,
        cvv: cardCVV,
      },
      (error, payload) => {
        if (error) {
          console.error('Payment error', error);
          onError(error);
          return;
        }

        const cardBrand =
          payload?.details?.cardType || eventsData.cardBrand || 'unknown';
        const localEventsData = {
          payment_method: 'Braintree',
          cardBrand,
          card_brand: cardBrand,
        };
        const extendedEventsData = {
          ...eventsData,
          ...localEventsData,
        };
        setAmplitudeUserProperties({ 'Payments gateways': 'Braintree' });
        dispatch(setEventDataList(localEventsData));
        dispatch(
          braintreePurchase(
            payload.nonce,
            payload.binData,
            braintreeThreeDSInstance.current,
            plan,
            token,
            analyticsParams,
            extendedEventsData,
            userUuid
          )
        );
      }
    );
  };

  const onBackClick = () => {
    sendAnalyticsEvents(EVENTS.subBuyClosed, eventsData);
    dispatch(setShowCheckout(false));
    dispatch(setCardholderName(''));
    dispatch(setPlan(defaultPlanId));
    clearFields();
  };

  const clearFields = () => {
    if (
      !(
        card?.current &&
        expiration?.current &&
        cvc?.current &&
        cardholder?.current
      )
    )
      return;
    cardholder.current.value = '';
    if (!isBraintree) {
      const fieldsToClear = [card.current, expiration.current, cvc.current];
      fieldsToClear.forEach((field) => field.clear());
    } else if (braintreeCardInstance.current) {
      ['number', 'expirationDate', 'cvv'].forEach((field) =>
        braintreeCardInstance.current.clear(field)
      );
    }
  };

  useEffect(() => {
    if (showCheckout) {
      dispatch(setIfCardFormShown(true));
      sendAnalyticsEvents(EVENTS.checkoutScreenShown, eventsData);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCheckout, dispatch]);

  useEffect(() => {
    if (isSuccess) {
      history.push('/checkout/success');
    }
  }, [isSuccess, history]);

  useLayoutEffect(() => {
    if (showError) {
      history.push('/checkout/error');
    }
  }, [showError, history]);

  const separatorElement = <PayWith>{t('or_checkout_width')}</PayWith>;

  const purchaseButton = (
    <UIButton
      disabled={!isFormValid}
      onClick={() => onPurchaseClick(null)}
      customId={`${isBraintree ? 'braintree' : 'stripe'}-continue-button`}
    >
      {t('confirm_payment')}
    </UIButton>
  );

  if (!stripePromise) {
    return <Loader suspenseShow />;
  }

  return (
    <StyledContainer>
      <BackButton analyticsEvent={onBackClick} />
      <Title>{t('checkout')}</Title>
      <CardIcons />
      {isBraintree ? (
        <Braintree
          btToken={btToken}
          braintreeInstance={braintreeInstance.current}
          cardInstance={braintreeCardInstance}
          threeDSInstance={braintreeThreeDSInstance}
          card={card}
          expiration={expiration}
          cvc={cvc}
          cardholder={cardholder}
          planDetails={planDetails}
          separatorElement={separatorElement}
          paypalShown={!!paypal.current}
        >
          {purchaseButton}
        </Braintree>
      ) : (
        <StyledCard>
          {load && (
            <Stripe
              card={card.current}
              expiration={expiration.current}
              cvc={cvc.current}
              inputRef={cardholder}
            />
          )}
        </StyledCard>
      )}
      {!isBraintree && purchaseButton}
      {(paymentRequest || paypal.current) && (
        <AlternativePaymentButtons>
          {!isBraintree && separatorElement}
          {paypal.current && (
            <PaypalButton id={'paypal-pay-button'} onClick={onPayPalClick} />
          )}
          {cardFormVisited && paymentRequest && (
            <Elements stripe={stripePromise}>
              <PaymentRequestContainer>
                <PaymentRequestButtonElement
                  options={{
                    paymentRequest,
                    id: 'apple-google-pay-button',
                    style: {
                      paymentRequestButton: {
                        height: '46px',
                        theme: 'light',
                        type: 'default',
                      },
                    },
                  }}
                />
              </PaymentRequestContainer>
            </Elements>
          )}
        </AlternativePaymentButtons>
      )}
      <StyledTerms>{t('billing_terms')}</StyledTerms>
      {isStage && (
        <input
          type={'hidden'}
          id={'test-skip-checkout'}
          onClick={() => history.push('/checkout/success')}
        />
      )}
    </StyledContainer>
  );
};

export default CheckoutScreen;
