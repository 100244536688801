import React, { useEffect, Suspense } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import * as Sentry from '@sentry/react';
import { isIOS, osVersion } from 'react-device-detect';
import GlobalStyle from './globalStyles';
import './App.scss';
import Router from './Router';
import './i18n';
import Loader from './UI/Loader';
import UIAlert from './UI/Alert';
import useInlineScript from './helper/useInlineScript';
import { setWebp } from './features/ui/uiSlice';
import {
  selectUserUuid,
  setUserToken,
  setUuid,
} from './features/signup/signupSlice';
import AF from './scripts/appsflyer';
import useBackgroundSrc from './helper/useBackgroundSrc';
import { appendLinkOnLoad, canUseWebp } from './helper/utils';
import FacebookPixel from 'react-facebook-pixel';
import { FB_PIXEL_ID } from './analytics/facebook';
import { GOOGLE_TRACKING_ID } from './analytics/GA';
import ReactGA from 'react-ga4';

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

function App() {
  const dispatch = useDispatch();
  const userUuid = useSelector(selectUserUuid);
  const { backgroundSources } = useBackgroundSrc();

  useInlineScript(AF, 'AppsFlyerSdkObject');

  useEffect(() => {
    const storageToken = window.sessionStorage?.getItem('access_token');

    if (storageToken) {
      dispatch(setUserToken(storageToken));
    }

    dispatch(setUuid());
  }, [dispatch]);

  useEffect(() => {
    dispatch(setWebp(isIOS && osVersion < 14 ? false : canUseWebp()));
    appendLinkOnLoad(`${backgroundSources.welcome}`, 'preload').then(() =>
      appendLinkOnLoad(`${backgroundSources.two}`)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    FacebookPixel.init(FB_PIXEL_ID, { external_id: userUuid });
    FacebookPixel.pageView();
  }, [userUuid]);

  useEffect(() => {
    if (GOOGLE_TRACKING_ID) {
      ReactGA.initialize(GOOGLE_TRACKING_ID);
    }
  }, []);

  return (
    <Suspense fallback={<Loader suspenseShow />}>
      <StyledWrapper>
        <GlobalStyle />
        <Loader showMessage />
        <BrowserRouter>
          <Route path={['/']} component={Router} />
        </BrowserRouter>
        <UIAlert />
      </StyledWrapper>
    </Suspense>
  );
}

export default Sentry.withProfiler(App);
