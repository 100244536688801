import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { isBrowser } from 'react-device-detect';
import { sendAnalyticsEvents } from '../../analytics/common';
import { EVENTS } from '../../analytics/events';
import {
  selectPlan,
  setCheckoutVisited,
  selectShowCheckout,
  setShowCheckout,
} from './plansSlice';
import {
  selectEventsData,
  setAnalyticsData,
  setEventDataList,
} from '../events/eventsSlice';
import DefaultPlans from './DefaultPlans';
import Slider from './Slider';
import CrossBtn from './CrossBtn';
import UIButton from '../../UI/UIButton';
import { centeredFlex, fadeInAnimation, screenPadding } from '../../mixins';
import {
  selectSkipPaywallDisabled,
  selectStripeAccountName,
} from '../signup/signupSlice';
import { PLANS } from '../../analytics/eCommerce';
import { useAnalyticsData } from '../checkout/helper';

const StyledContainer = styled.div`
  ${centeredFlex};
  ${screenPadding};
  ${isBrowser && fadeInAnimation};
  min-height: 100%;
  position: relative;

  @media screen and (min-width: 768px) {
    height: calc(100vh - 116px);
    min-height: unset;
  }
  @media screen and (max-width: 430px) {
    justify-content: flex-start;
  }
  @media screen and (max-width: 430px) and (min-height: 650px) {
    justify-content: center;
  }
`;

const PlansScreen = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isSkipPaywallDisabled = useSelector(selectSkipPaywallDisabled);
  const stripeAccountName = useSelector(selectStripeAccountName);
  const plan = useSelector(selectPlan);
  const amplitudeEventsData = useSelector(selectEventsData);
  const analyticsParams = useAnalyticsData();
  const showCheckout = useSelector(selectShowCheckout);

  useEffect(() => {
    dispatch(setAnalyticsData(analyticsParams));
  }, [analyticsParams, dispatch]);

  useEffect(() => dispatch(setCheckoutVisited(true)), [dispatch]);

  useEffect(() => {
    !showCheckout &&
      sendAnalyticsEvents(EVENTS.plansScreenShown, amplitudeEventsData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCheckout]);

  const handleSetAmplitudeEvents = () => {
    if (!plan) return;
    const { productId, duration, description, price, periodPrice } =
      PLANS[plan];

    const localEventsData = {
      Value: price,
      value: Math.round(periodPrice * 100),
      currency: 'USD',
      price: periodPrice,
      Duration: duration,
      description,
      periodPrice,
      product_id: productId,
      duration,
      stripe_account_name: stripeAccountName,
    };
    dispatch(setEventDataList(localEventsData));
    sendAnalyticsEvents(EVENTS.onboardingPaywallChosen, {
      ...amplitudeEventsData,
      ...localEventsData,
    });
  };
  const onBuyClick = () => {
    handleSetAmplitudeEvents();
    dispatch(setShowCheckout(true));
  };

  return (
    <StyledContainer>
      {!isSkipPaywallDisabled && <CrossBtn />}
      <Slider />
      <DefaultPlans />
      <UIButton onClick={onBuyClick} customId={'paywall-continue-button-1'}>
        {t('continue')}
      </UIButton>
    </StyledContainer>
  );
};

export default PlansScreen;
