import React from 'react';
import styled from 'styled-components';
import Carousel from 'react-bootstrap/Carousel';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { wh, fontSize } from '../../mixins';
import { selectWebp } from '../ui/uiSlice';

const StyledCarousel = styled(Carousel)`
  max-height: 40vh;

  @media screen and (min-width: 360px) {
    margin-top: 20px;
  }
`;

const StyledItem = styled.div`
  ${wh('100%', 'auto')};
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 245px;
  color: #fff;

  b {
    ${fontSize(24)};
    line-height: 29px;
    margin: 14px 0 8px;
    text-align: center;
  }

  p {
    ${fontSize(14)};
    line-height: 19px;
    text-align: center;
    font-weight: 300;
    width: 210px;
  }
`;

const Slider = () => {
  const { t } = useTranslation();
  const isWebp = useSelector(selectWebp);

  const SLIDES = [
    {
      src: isWebp
        ? '../assets/plans-slider/image1.webp'
        : '../assets/plans-slider/image1.png',
      title: t('check_location'),
      text: t('screen_2_text'),
    },
    {
      src: isWebp
        ? '../assets/plans-slider/image2.webp'
        : '../assets/plans-slider/image2.png',
      title: t('includes_all'),
      text: t('share_premium'),
    },
    {
      src: isWebp
        ? '../assets/plans-slider/image3.webp'
        : '../assets/plans-slider/image3.png',
      title: t('places'),
      text: t('get_notified'),
    },
    {
      src: isWebp
        ? '../assets/plans-slider/image4.webp'
        : '../assets/plans-slider/image4.png',
      title: t('schedule_alerts'),
      text: t('something_goes_unplanned'),
    },
  ];

  const renderSlide = (i) => (
    <Carousel.Item key={i.title}>
      <StyledItem>
        <img src={i.src} width="68" height="68" alt={i.title} />
        <b>{i.title}</b>
        <p>{i.text}</p>
      </StyledItem>
    </Carousel.Item>
  );

  return (
    <StyledCarousel controls={false}>{SLIDES.map(renderSlide)}</StyledCarousel>
  );
};

export default Slider;
