import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { centeredBG, firstScreensContainer } from '../../mixins';
import ScreenContent from '../../UI/ScreenContent';
import ProgrBar from '../../UI/ProgrBar';
import { sendAnalyticsEvents } from '../../analytics/common';
import { EVENTS } from '../../analytics/events';
import { selectWebp } from '../ui/uiSlice';
import { selectEventsData } from '../events/eventsSlice';
import useBackgroundSrc from '../../helper/useBackgroundSrc';
import { appendLink } from '../../helper/utils';

const ScreenContainer = styled.div`
  background-image: ${(props) =>
    props.supportsWebP
      ? `url('../assets/desktop/screen-three-desk.webp')`
      : `url('../assets/desktop/screen-three-desk.png')`};
  ${firstScreensContainer};
  ${centeredBG};
  background-position: top;
  @media screen and (max-width: 430px) {
    background-image: ${(props) =>
      props.supportsWebP
        ? `url('../assets/mobile/screen-three.webp')`
        : `url('../assets/mobile/screen-three.png')`};
    background-position: center;
  }
`;

const ScreenThree = (props) => {
  const { t } = useTranslation();
  const isWebp = useSelector(selectWebp);
  const eventsData = useSelector(selectEventsData);
  const { backgroundSources } = useBackgroundSrc();

  useEffect(
    () => sendAnalyticsEvents(EVENTS.screen3Shown, eventsData),
    [eventsData]
  );

  useEffect(() => {
    appendLink(`${backgroundSources.four}`);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleBtnClick = () => {
    props.history.push('/crash-detection');
  };

  return (
    <ScreenContainer supportsWebP={isWebp}>
      <ProgrBar now={75} />
      <ScreenContent
        title={t('place_alerts')}
        text={t('screen_3_text')}
        btn={t('continue')}
        onClick={handleBtnClick}
      />
    </ScreenContainer>
  );
};

export default ScreenThree;
