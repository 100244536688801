import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { loadStripe } from '@stripe/stripe-js';
import { selectStripePublishKey } from '../features/signup/signupSlice';

const withStripeInit = (WrappedComponent) => (props) => {
  const stripePk = useSelector(selectStripePublishKey);
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    if (!stripePromise) {
      setStripePromise(loadStripe(stripePk));
    }
  }, [stripePk, stripePromise]);

  return <WrappedComponent {...props} stripePromise={stripePromise} />;
};

export default withStripeInit;
