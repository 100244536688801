import React from 'react';
import styled from 'styled-components';

const Circle = styled.div`
  width: 12px;
  height: 12px;
  background: ${(props) => (props.active ? '#F09A51' : '#D4D4D4')};
  border-radius: 50px;
  position: relative;
  transition: background 0.3s linear 0.1s;

  &::before {
    content: '';
    width: 44px;
    height: 2px;
    background-color: ${(props) => (props.active ? '#F09A51' : '#D4D4D4')};
    position: absolute;
    right: 100%;
    top: 5px;
    transition: background 0.3s linear;
  }

  &:first-child {
    &::before {
      content: none;
    }
  }
`;

const StepperBox = styled.div`
  width: 124px;
  margin: 8px auto 24px;
  display: flex;
  justify-content: space-between;
`;

const Stepper = ({ stepsArray, active }) => {
  const renderStep = (i) => <Circle key={i.id} active={i.id <= active} />;

  return <StepperBox>{stepsArray.map(renderStep)}</StepperBox>;
};

export default Stepper;
