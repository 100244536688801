const EVENTS = {
  OnboardingStarted: 'session_start',
  OnboardWelcomeShown: 'session_start',
  OnboardingPaymentShown: 'subs_purchase_show',
  SubBuyTapped: 'subs_purchase_started',
  PaywallSubscribed: 'subs_purchase_completed',
  SubsPurchaseFailed: 'subs_purchase_failed',
  PaywallClosed: 'subs_purchase_screen_close',
};

export default EVENTS;
