import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import sessionStorage from 'redux-persist/lib/storage/session';
import { persistReducer, createMigrate } from 'redux-persist';
import thunk from 'redux-thunk';
import signupReducer from '../features/signup/signupSlice';
import errorReducer from '../features/error/errorSlice';
import loaderReducer from '../features/loader/loaderSlice';
import optionsReducer from '../features/options/optionsSlice';
import plansReducer from '../features/plans/plansSlice';
import checkoutReducer from '../features/checkout/checkoutSlice';
import uiReducer from '../features/ui/uiSlice';
import eventsReducer from '../features/events/eventsSlice';

const MIGRATION_DEBUG = false;

const migrations = {
  0: (state) => ({
    ...state,
  }),
};

const reducers = combineReducers({
  error: errorReducer,
  loader: loaderReducer,
  options: optionsReducer,
  checkout: checkoutReducer,
  plans: plansReducer,
  ui: uiReducer,
  signup: signupReducer,
  events: eventsReducer,
});

const persistConfig = {
  key: 'root',
  storage: sessionStorage,
  whitelist: ['plans', 'ui', 'signup', 'events'],
  version: 0,
  migrate: createMigrate(migrations, { debug: MIGRATION_DEBUG }),
};

const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  devTools: process.env.REACT_APP_ENV === 'stage',
  middleware: [thunk],
});
