import React from 'react';
import styled from 'styled-components';
import ProgressBar from 'react-bootstrap/ProgressBar';

const StyledBar = styled(ProgressBar)`
  border-radius: 0 !important;
  height: 6px !important;
  background-color: transparent !important;

  & > div {
    background-color: #fba253 !important;
  }
`;

const ProgrBar = (props) => (
  <StyledBar variant="warning" now={props.now} key={1} />
);

export default ProgrBar;
