import React from 'react';
import styled from 'styled-components';
import { cardBrandsIcons } from './Icons';
import { fadeInAnimation, fontSize, wh } from '../../../mixins';
import { isBrowser } from 'react-device-detect';

const isArabic = navigator.language.startsWith('ar');
const textAlign = isArabic ? 'right' : 'left';

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin: 40px auto 24px;
  background-color: #fff;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  position: relative;
  z-index: 10;
  ${isBrowser && fadeInAnimation};

  & > * {
    position: relative;
    z-index: 10;
  }

  &:before,
  &:after {
    content: '';
    display: block;
    ${wh('90%')};
    position: absolute;
    z-index: -5;
    top: -10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #e9e9e9;
    border-radius: inherit;
    box-shadow: inherit;
  }

  &:after {
    ${wh('80%')};
    top: -20px;
    background-color: #d4d4d4;
    z-index: -6;
    box-shadow: none;
  }
`;

const StyledCard = styled.div`
  width: 100%;
  min-height: 222px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: ${isArabic ? 'flex-end' : 'flex-start'};
  position: relative;
  background-color: #fff;
  z-index: 10;

  & > * {
    position: relative;
    z-index: 10;
  }
`;

const StyledFieldWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > div {
    height: 24px;
    overflow: hidden;
  }
`;

const StyledRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const StyledLabel = styled.span`
  color: #323232;
  ${fontSize(14)};
  line-height: 18px;
  text-align: ${textAlign};
  font-weight: 300;
  padding: 7px 0;
`;

const StyledInput = styled.input`
  ${wh('100%', '28px')};
  color: #00a08b;
  padding: 3px 8px 3px 0;
  background-color: transparent;
  border: none;
  ${fontSize(18)};
  line-height: 22px;
  font-weight: 300;
  text-align: ${textAlign};

  &::-webkit-credit-card-auto-fill-button {
    visibility: hidden;
    display: none !important;
    pointer-events: none;
    position: absolute;
    right: 0;
  }

  &::placeholder {
    color: #adadad;
    letter-spacing: 1.2px;
    font-weight: 300;
    ${fontSize(18)};
    line-height: 22px;
  }

  &:focus,
  &:hover,
  &:active {
    outline: none;
  }
`;

const StyledBrandIcon = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
  left: 16px;
  ${textAlign}: auto;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    height: auto;
  }
`;

const CardForm = ({
  method,
  card,
  expiration,
  cvc,
  cardholder,
  cardBrand,
  setName,
  t,
}) => {
  const isStripe = method === 'stripe';

  return (
    <StyledWrapper>
      <StyledCard>
        <StyledFieldWrapper>
          <StyledLabel>{t('card_number')}</StyledLabel>
          <div id={'card-number-element'} ref={!isStripe ? card : null} />
        </StyledFieldWrapper>

        <StyledRow>
          <StyledFieldWrapper>
            <StyledLabel>{t('MM/PP')}</StyledLabel>
            <div
              id={'card-expiry-element'}
              ref={!isStripe ? expiration : null}
            />
          </StyledFieldWrapper>

          <StyledFieldWrapper>
            <StyledLabel>{t('CVC2')}</StyledLabel>
            <div id={'card-cvc-element'} ref={!isStripe ? cvc : null} />
          </StyledFieldWrapper>
        </StyledRow>

        <StyledFieldWrapper>
          <StyledLabel>{t('cardholder_name')}</StyledLabel>
          <StyledInput
            ref={cardholder}
            onChange={(e) => setName(e.target.value)}
            type="text"
            autocomplete="cc-name"
            placeholder={t('name')}
          />
        </StyledFieldWrapper>

        <StyledBrandIcon>{cardBrandsIcons[cardBrand]}</StyledBrandIcon>
      </StyledCard>
    </StyledWrapper>
  );
};

export default CardForm;
