import React from 'react';
import styled, { keyframes } from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { wh, fontSize } from '../mixins';
import { selectLoader, selectLoaderType } from '../features/loader/loaderSlice';

const spinning = keyframes`
    to {
        transform: rotate(360deg);
       }
`;

const StyledSpinner = styled.div`
  top: 0;
  left: 0;
  background: rgba(0, 191, 165, 0.85);
  ${wh('100%')};
  position: fixed;
  z-index: 1051;

  & > div {
    box-sizing: border-box;
    position: absolute;
    top: 50%;
    left: 50%;
    ${wh('80px', '75px')};
    margin-top: -38px;
    margin-left: -40px;
    animation: ${spinning} 1.5s linear infinite;
    background-image: url('../assets/spinner-image.svg');
    z-index: 2;
  }

  & > p {
    position: absolute;
    ${fontSize(15)};
    color: #fff;
    width: 180px;
    text-align: center;
    top: 50%;
    left: 50%;
    margin-top: 60px;
    margin-left: -90px;
    font-weight: 300;
    line-height: 24px;
  }
`;

const Loader = ({ suspenseShow, showMessage }) => {
  const { t } = useTranslation();

  const storeShow = useSelector(selectLoader);
  const loaderType = useSelector(selectLoaderType);

  const loaderText =
    loaderType === 'checkout' ? t('loading_text_payment') : t('loading_text');

  if (suspenseShow || storeShow) {
    return (
      <StyledSpinner id={'loading-element'}>
        <div />
        {showMessage && <p>{loaderText}</p>}
      </StyledSpinner>
    );
  }

  return null;
};

export default Loader;
