import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { isBrowser } from 'react-device-detect';
import {
  MediumTitle,
  screenPadding,
  fadeInAnimation,
  centeredFlex,
} from '../../mixins';
import Card from './Card';
import UIButton from '../../UI/UIButton';
import { selectDone, selectAnsweredCount } from './optionsSlice';
import Stepper from '../../UI/Stepper';
import { sendAnalyticsEvents } from '../../analytics/common';
import { EVENTS } from '../../analytics/events';
import { selectEventsData } from '../events/eventsSlice';

const isStage = process.env.REACT_APP_ENV === 'stage';

const StyledOptionsScreen = styled.div`
  ${centeredFlex};
  ${screenPadding};
  ${isBrowser && fadeInAnimation};

  @media screen and (min-width: 1024px) {
    height: calc(100vh - 116px);
    min-height: unset;
    max-width: 1024px;
  }
`;

const StyledContainer = styled.div`
  width: 100%;
  margin-bottom: auto;
`;

const StyledListWrapper = styled.div`
  @media screen and (min-width: 1000px) {
    width: 100%;
    display: flex;
    justify-content: space-between;

    > div {
      width: 327px;
    }
  }
`;

const Title = styled.h1`
  ${MediumTitle};
`;

const OptionsScreen = ({ history }) => {
  const { t } = useTranslation();
  const isSurveyDone = useSelector(selectDone);
  const answerCount = useSelector(selectAnsweredCount);
  const eventsData = useSelector(selectEventsData);
  const [initEventSent, setInitEventStatus] = useState(false);

  useEffect(() => {
    if (initEventSent) return;

    sendAnalyticsEvents(EVENTS.optionsScreenShown, eventsData);
    setInitEventStatus(true);
  }, [initEventSent, eventsData]);

  const QUESTIONS = [
    {
      id: 1,
      title: t('question_1.title'),
      name: 'WhomToLocate',
      options: [
        {
          id: 'Loved One',
          name: t('question_1.option_1'),
        },
        {
          id: 'Friend',
          name: t('question_1.option_2'),
        },
        {
          id: 'Family Member',
          name: t('question_1.option_3'),
        },
      ],
    },
    {
      id: 2,
      title: t('question_2.title'),
      name: 'ArriveOrLeave',
      options: [
        {
          id: 'Home',
          name: t('question_2.option_1'),
        },
        {
          id: 'Work',
          name: t('question_2.option_2'),
        },
        {
          id: 'School',
          name: t('question_2.option_3'),
        },
        {
          id: 'None of the above',
          name: t('question_2.option_4'),
        },
      ],
    },
    {
      id: 3,
      title: t('question_3.title'),
      name: 'Following',
      options: [
        {
          id: 'Schedule',
          name: t('question_3.option_1'),
        },
        {
          id: 'Driving Patterns',
          name: t('question_3.option_2'),
        },
        {
          id: 'Most Visited Places',
          name: t('question_3.option_3'),
        },
        {
          id: "Phone's Battery Status",
          name: t('question_3.option_4'),
        },
        {
          id: 'None of the above',
          name: t('question_3.option_5'),
        },
      ],
    },
  ];

  const renderQuestions = (item, i) => (
    <div key={item.title}>
      <Card title={item.title} options={item.options} id={`question${i + 1}`} />
    </div>
  );

  const onContinueClick = () => {
    if (isSurveyDone) {
      history.push('/signup/creating-your-profile');
    }
  };

  const onSkipClick = () => {
    isStage && history.push('/signup/creating-your-profile');
  };

  return (
    <StyledOptionsScreen>
      <StyledContainer>
        <Title onClick={onSkipClick} id={'test-options-screen-skip'}>
          {t('tap_options')}
        </Title>
        <Stepper stepsArray={QUESTIONS} active={answerCount} />
        <StyledListWrapper>{QUESTIONS.map(renderQuestions)}</StyledListWrapper>
      </StyledContainer>
      <UIButton disabled={!isSurveyDone} onClick={() => onContinueClick()}>
        {t('save_and_continue')}
      </UIButton>
    </StyledOptionsScreen>
  );
};

export default OptionsScreen;
