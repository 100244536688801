import { sendFirebaseEvent, setUserId } from './firebase';
import { sendAFEvent, setAFCustomerId } from './appsflyer';
import { sendAmplitudeData, setAmplitudeUserId } from './amplitude';
import { sendGismartData } from './gismart';
import { sendLockerStudioData } from './lockerStudio';
import gismartEvents from './gismartEvents';

const isStage = process.env.REACT_APP_ENV === 'stage';

export const sendAnalyticsEvents = (eventName, eventParams = {}) => {
  const gismartEventName = gismartEvents[eventName];

  sendFirebaseEvent(eventName, eventParams);
  sendAFEvent(eventName, eventParams);
  sendAmplitudeData(eventName, eventParams);
  sendGismartData(gismartEventName || eventName, eventParams);
  sendLockerStudioData(gismartEventName || eventName, eventParams);
  pushGoogleTag(eventName, eventParams);
};

export const setAnalyticsUserId = (userId = '') => {
  setAmplitudeUserId(userId);
  setAFCustomerId(userId);
  setUserId(userId);
};

export const pushGoogleTag = (eventName, eventParams) => {
  if (isStage) return;
  window.gtag?.('event', eventName, eventParams);
};

export const pushFBEvent = (data, eventId) => {
  if (window.fbq) {
    window.fbq('track', `Purchase${isStage ? '_test' : ''}`, data, {
      eventID: eventId,
    });
  }
};
