import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectWebp } from '../features/ui/uiSlice';
import useWindowWidth from './useWindowWidth';

export default function useBackgroundSrc() {
  const isWebp = useSelector(selectWebp);
  const windowWidth = useWindowWidth();

  const getInitBackgroundSources = () => {
    if (windowWidth > 430 && isWebp) {
      return {
        welcome: './assets/desktop/screen-one-desk.webp',
        two: './assets/desktop/screen-two-desk.webp',
        three: './assets/desktop/screen-three-desk.webp',
        four: './assets/desktop/screen-four-desk.webp',
      };
    }
    if (windowWidth < 430 && isWebp) {
      return {
        welcome: './assets/mobile/screen-one.webp',
        two: './assets/mobile/screen-two.webp',
        three: './assets/mobile/screen-three.webp',
        four: './assets/mobile/screen-four.webp',
      };
    }
    if (windowWidth > 430 && !isWebp) {
      return {
        welcome: './assets/desktop/screen-one-desk.png',
        two: './assets/desktop/screen-two-desk.png',
        three: './assets/desktop/screen-three-desk.png',
        four: './assets/desktop/screen-four-desk.png',
      };
    }
    return {
      welcome: './assets/mobile/screen-one.png',
      two: './assets/mobile/screen-two.png',
      three: './assets/mobile/screen-three.png',
      four: './assets/mobile/screen-four.png',
    };
  };

  const [backgroundSources, setBackgroundSources] = useState(
    getInitBackgroundSources()
  );

  useEffect(() => {
    const backgroundSources = getInitBackgroundSources();
    setBackgroundSources(backgroundSources);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    backgroundSources,
  };
}
