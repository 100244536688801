import React, { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { setEmail } from './signupSlice';
import useDebounce from '../../helper/useDebounce';
import { wh, fontSize, centeredFlex } from '../../mixins';
import { setEventData } from '../events/eventsSlice';
// eslint-disable-next-line
const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const isArabic = navigator.language.startsWith('ar');
const textAlign = isArabic ? 'right' : 'left';

const Container = styled.div`
  width: 90%;
  min-height: 86px;
  ${centeredFlex};
  margin-bottom: 30px;
`;

const Input = styled.input`
  ${wh('100%', '48px')};
  border-width: 1px;
  border-style: solid;
  border-color: ${(props) => (props.error ? '#FF5B5B' : '#E9E9E9')};
  box-sizing: border-box;
  border-radius: 4px;
  background: #ffffff;
  ${fontSize(14)};
  line-height: 18px;
  color: #323232;
  padding: 14px 16px 15px;
  text-align: ${textAlign};

  &::placeholder {
    color: #adadad;
    font-weight: 300;
  }

  &:focus,
  &:active,
  &:hover {
    outline: none;
  }
`;

const DomainBtn = styled.button`
  background: #ffffff;
  filter: drop-shadow(0px 2px 8px rgba(146, 146, 146, 0.1));
  border-radius: 24px;
  ${fontSize(12)};
  line-height: 16px;
  color: #323232;
  border: none;
  padding: 5px 13px;
  margin-right: 5px;
  box-sizing: border-box;
  ${wh('fit-content', '30px')};
  @media screen and (max-width: 350px) {
    margin-top: 10px;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    background-color: #f09a51;
    color: #fff;
  }
`;

const BtnContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: ${isArabic ? 'row-reverse' : 'row'};
  @media screen and (max-width: 350px) {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;

const SignupInput = () => {
  const [value, setValue] = useState('');
  const [isValid, setIsValid] = useState(false);
  const [selected, setSelected] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const debouncedEmail = useDebounce(value, 200);

  useEffect(() => {
    if (debouncedEmail) {
      dispatch(setEmail({ email: debouncedEmail, isValid }));
      dispatch(setEventData({ id: 'user_email', data: debouncedEmail }));
    }
  }, [debouncedEmail, dispatch, isValid]);

  useEffect(() => setIsValid(EMAIL_REGEX.test(value)), [value]);

  useEffect(() => !value && setSelected(null), [value]);

  const onAddDomainClick = (value) => {
    setValue((prevState) => (prevState += value));
    setSelected(value);
  };

  const renderDomains = useCallback(() => {
    const DOMAINS = [
      { value: '@gmail.com' },
      { value: '@yahoo.com' },
      { value: '@hotmail.com' },
    ];

    const renderDomainItem = (item, i) => (
      <DomainBtn
        key={item.value}
        disabled={selected}
        onClick={() => onAddDomainClick(item.value)}
        id={`domain-button-${i + 1}`}
      >
        {item.value}
      </DomainBtn>
    );

    if (value && selected) {
      return DOMAINS.filter((i) => i.value === selected).map(renderDomainItem);
    }
    return DOMAINS.map(renderDomainItem);
  }, [value, selected]);

  return (
    <Container>
      <Input
        type={'email'}
        error={value && !isValid}
        autoComplete={'email'}
        value={value}
        onChange={(evt) => setValue(evt.target.value)}
        placeholder={t('enter_your_email')}
        id={'sign-up-input'}
      />
      <BtnContainer>{renderDomains()}</BtnContainer>
    </Container>
  );
};

export default SignupInput;
