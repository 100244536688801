import { css, keyframes } from 'styled-components';
import { isBrowser } from 'react-device-detect';

export const outlineColor = '#00BFA5';
export const mainGreen = '#00BFA5';

export const fontSize = (size, base = 16) => `
  font-size: ${size}px;
  font-size: calc(${size / base} * 1rem);
`;

export const centeredFlex = `
  width: 100%;
  max-width: 400px; 
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: auto;
  margin-right: auto; 
`;

export const wh = (w, h = w) => `
  width: ${w};
  height: ${h};
`;

export const screenPadding = `
  padding: 24px 6px 140px;
  
  @media screen and (min-width: 767px) {
    padding-left: 24px;
}
`;

const shadow =
  'linear-gradient(180deg, rgba(0, 0, 0, 0.0001) 2%, rgba(0, 0, 0, 0.85) 60.88%)';

export const bgWithShadow = (url) => `
    background-image: ${shadow}, url(${url});
`;

export const centeredBG = `
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
`;

const fadeIn = keyframes`
 0% { opacity: 0; }
 100% { opacity: 1;}
`;

export const fadeInAnimation = css`
  animation-name: ${fadeIn};
  animation-duration: 0.8s;
`;

export const fadeInDelay = css`
  animation-name: ${fadeIn};
  animation-duration: 1s;
  animation-delay: 5s;
  animation-fill-mode: forwards;
`;

export const MediumTitle = `
    ${fontSize(24)};
    line-height: 29px;
    text-align: center;
    color: #fff;
    font-weight: 600;
    
    @media screen and (max-width: 767px) {
      ${fontSize(18)};
    }
    
`;

export const firstScreensContainer = css`
  ${wh('100%')};
  background-position: center bottom, center top;
  background-size: auto, 168vw auto;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  ${isBrowser && fadeInAnimation};

  @media screen and (min-width: 768px) {
    background-size: 605px auto;
    padding-bottom: 80px;
  }
`;

export const greyText = `
  font-style: normal;
  font-weight: 500;
  ${fontSize(15)};
  line-height: 18px;
  color: #9C9C9C;
`;

export const LargeTitleDark = `
  font-style: normal;
  font-weight: 500;
  ${fontSize(38)};
  line-height: 40x;
  color: #323232;
  text-align: center;
  
  @media screen and (max-width: 767px) {
    ${fontSize(26)};
    line-height: 32x;
  }
`;
