import axios from 'axios';

const { REACT_APP_API_URL2 } = process.env;

export const sendStripeReq = async (stripe, card, name) => {
  try {
    const res = await stripe.createPaymentMethod({
      type: 'card',
      card,
      billing_details: {
        name,
      },
    });

    return res;
  } catch (e) {
    return e;
  }
};

export const subscribeToPriceChange = async (pollingId) => {
  const url = `${REACT_APP_API_URL2}/polling/get-result/${pollingId}`;

  try {
    const response = await axios.get(url);

    const {
      data: {
        data: { result_data },
      },
    } = response;

    if (response.status === 202) {
      return await subscribeToPriceChange(pollingId);
    }

    return result_data;
  } catch (e) {
    return e.response?.data?.data?.result_data;
  }
};

export const getPollingID = async (
  nonce,
  plan,
  token,
  analyticsParams,
  eventsData,
  onErrorHandler,
  userUuid,
  upsell = '',
  payment_intent_id
) => {
  const url = `${REACT_APP_API_URL2}/users/me/subscriptions/polling/${plan}`;

  const requestData = {
    ...analyticsParams,
    user_data: {
      ...analyticsParams['user_data'],
      ...eventsData,
    },
  };

  const body = {
    gateway: 'stripe',
    payment_method_nonce: nonce,
    coupon: '',
    fb_event_id: `${nonce}_${userUuid}${upsell}`,
    payment_intent_id,
    ...requestData,
  };

  try {
    const res = await axios.post(url, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    const {
      data: {
        data: { polling_result_id },
      },
    } = res;

    return polling_result_id;
  } catch (e) {
    onErrorHandler(e.response?.data?.errors?.messages);
  }
};

export const getSubscriptionID = async (
  nonce,
  plan,
  token,
  analyticsParams,
  eventsData,
  userUuid,
  upsell = ''
) => {
  const url = `${REACT_APP_API_URL2}/users/me/subscriptions/${plan}`;

  const requestData = {
    ...analyticsParams,
    user_data: {
      ...analyticsParams['user_data'],
      ...eventsData,
    },
  };

  const body = {
    gateway: 'braintree',
    payment_method_nonce: nonce,
    coupon: '',
    fb_event_id: `${nonce}_${userUuid}${upsell}`,
    ...requestData,
  };

  try {
    const res = await axios.post(url, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    });

    const { data } = res;

    return data;
  } catch (e) {
    return e.response?.data || { errors: 'An error has occurred' };
  }
};
